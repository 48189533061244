import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const ScanPeople: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='32'
      height='33'
      viewBox='0 0 32 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_362_2651)'>
        <path
          d='M9.13919 5.4499C6.81978 5.4499 4.9499 7.31978 4.9499 9.63919V12.6749C4.9499 13.3791 4.37907 13.9499 3.6749 13.9499C2.97074 13.9499 2.3999 13.3791 2.3999 12.6749V9.63919C2.3999 5.91145 5.41145 2.8999 9.13919 2.8999H12.1749C12.8791 2.8999 13.4499 3.47074 13.4499 4.1749C13.4499 4.87907 12.8791 5.4499 12.1749 5.4499H9.13919Z'
          fill='#00A7F0'
        />
        <path
          d='M18.5499 4.1749C18.5499 3.47074 19.1207 2.8999 19.8249 2.8999H22.8606C26.5884 2.8999 29.5999 5.91145 29.5999 9.63919V12.6749C29.5999 13.3791 29.0291 13.9499 28.3249 13.9499C27.6207 13.9499 27.0499 13.3791 27.0499 12.6749V9.63919C27.0499 7.31978 25.18 5.4499 22.8606 5.4499H19.8249C19.1207 5.4499 18.5499 4.87907 18.5499 4.1749Z'
          fill='#00A7F0'
        />
        <path
          d='M28.3249 19.0499C29.0291 19.0499 29.5999 19.6207 29.5999 20.3249V22.4499C29.5999 26.6816 26.1816 30.0999 21.9499 30.0999H19.8249C19.1207 30.0999 18.5499 29.5291 18.5499 28.8249C18.5499 28.1207 19.1207 27.5499 19.8249 27.5499H21.9499C24.7732 27.5499 27.0499 25.2732 27.0499 22.4499V20.3249C27.0499 19.6207 27.6207 19.0499 28.3249 19.0499Z'
          fill='#00A7F0'
        />
        <path
          d='M3.6749 19.0499C4.37907 19.0499 4.9499 19.6207 4.9499 20.3249V23.3606C4.9499 25.68 6.81978 27.5499 9.13919 27.5499H12.1749C12.8791 27.5499 13.4499 28.1207 13.4499 28.8249C13.4499 29.5291 12.8791 30.0999 12.1749 30.0999H9.13919C5.41145 30.0999 2.3999 27.0884 2.3999 23.3606V20.3249C2.3999 19.6207 2.97074 19.0499 3.6749 19.0499Z'
          fill='#00A7F0'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M11.3249 12.6787C11.3249 10.0996 13.4087 7.9999 15.9999 7.9999C18.5815 7.9999 20.6749 10.0977 20.6749 12.6787L20.6749 12.684C20.6644 15.2033 18.6789 17.2646 16.1653 17.3492C16.1107 17.351 16.0561 17.3493 16.0018 17.3442L15.9989 17.3442C15.9924 17.3442 15.9853 17.3447 15.9853 17.3447C15.9326 17.3494 15.8797 17.351 15.8268 17.3492C13.3142 17.2646 11.3249 15.2031 11.3249 12.6787ZM15.9999 10.5499C14.8235 10.5499 13.8749 11.5014 13.8749 12.6787C13.8749 13.8146 14.7498 14.731 15.8552 14.7979C15.9477 14.7931 16.0437 14.7928 16.1387 14.7978C17.2417 14.7301 18.1188 13.8151 18.1249 12.6761C18.1235 11.5019 17.1696 10.5499 15.9999 10.5499Z'
          fill='#00A7F0'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M16.0055 18.1999C17.5076 18.1999 19.0817 18.4956 20.3382 19.1593L19.7426 20.2867L20.3364 19.1584C21.4486 19.7436 22.3749 20.7189 22.3749 22.0205C22.3749 23.3208 21.4499 24.2974 20.339 24.8856C19.0792 25.5523 17.5032 25.8499 15.9999 25.8499C14.4966 25.8499 12.9206 25.5523 11.6608 24.8856C10.5498 24.3002 9.6249 23.3254 9.6249 22.0249C9.6249 20.7246 10.5497 19.7481 11.6605 19.1599L11.665 19.1576C12.9269 18.4958 14.5029 18.1999 16.0055 18.1999ZM12.8516 21.4147C12.5209 21.5901 12.3407 21.7579 12.2523 21.8737C12.2093 21.9299 12.1905 21.9702 12.1825 21.9922C12.1749 22.0131 12.1749 22.0249 12.1749 22.0249C12.1749 22.0249 12.1749 22.0365 12.1823 22.0569C12.1902 22.0784 12.2087 22.1182 12.2513 22.1738C12.3393 22.2886 12.5193 22.4559 12.8509 22.6303L12.8535 22.6317C13.6502 23.0533 14.7886 23.2999 15.9999 23.2999C17.211 23.2999 18.3494 23.0534 19.146 22.6319C19.478 22.4561 19.6589 22.2877 19.7475 22.1717C19.7905 22.1155 19.8093 22.0752 19.8173 22.0532C19.8249 22.0323 19.8249 22.0205 19.8249 22.0205C19.8249 22.0205 19.8249 22.0089 19.8175 21.9885C19.8096 21.967 19.7911 21.9272 19.7485 21.8716C19.6605 21.7567 19.4805 21.5895 19.1489 21.415L19.1471 21.4141C18.3546 20.9954 17.218 20.7499 16.0055 20.7499C14.7947 20.7499 13.6534 20.9948 12.8516 21.4147Z'
          fill='#00A7F0'
        />
      </g>
      <defs>
        <clipPath id='clip0_362_2651'>
          <rect
            width='32'
            height='32'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ScanPeople;
