import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '../../../../components/ui';
import styles from './miniCard.module.css';
import { Doughnut } from 'react-chartjs-2';

import PageLayout from '../../../../components/layout/Page';
import * as dashboardActions from '../../../../reducers/dashboard/actions';
import Refresh from '../../../../components/icons/Refresh';
import { useSelector } from 'react-redux';
import Container from '../../../../components/ui/container';
import Skeleton from '../../../../components/ui/skeleton';
import Rectangle from '../../../../components/icons/Rectangle';

const MiniCard = ({ title, values, data, dataFormated }: any) => {
  const infos = [
    { label: 'Presentes', color: '#0099FE' },
    { label: 'Ausentes', color: 'rgba(34, 34, 34, 0.24)' },
  ];

  return (
    <Box className={styles.card}>
      <Typography className={styles.cardFirstTitle}>{title}</Typography>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Typography className={styles.cardFirstQuantity}>{values}</Typography>
        {data.checked > 0 && (
          <Typography className={styles.doughnutBoxDataFirstLinePercentage}>
            {((data?.checked / data?.total) * 100).toFixed(2)}%
          </Typography>
        )}
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <Box className={styles.doughnutBox}>
          <Doughnut
            data={dataFormated}
            options={{ maintainAspectRatio: false }}
          />
        </Box>
        <Box className={styles.doughnutBoxData}>
          {infos.map((info, index) => (
            <Box key={index} className={styles.doughnutBoxInfo}>
              <Rectangle fill={info.color} />
              <Typography className={styles.doughnutBoxInfoText}>
                {info.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MiniCard;
