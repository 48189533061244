import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Ticket: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.4572 10.1334C10.4572 9.61796 10.0427 9.2001 9.53144 9.2001C9.02018 9.2001 8.60573 9.61796 8.60573 10.1334V13.8668C8.60573 14.3822 9.02018 14.8001 9.53144 14.8001C10.0427 14.8001 10.4572 14.3822 10.4572 13.8668V10.1334Z'
        fill='#00A7F0'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.06287 3.6001C5.10157 3.6001 3.51343 3.80907 2.48002 4.78586C1.4253 5.7828 1.20001 7.33171 1.20001 9.2001V9.66676C1.20001 10.1822 1.61447 10.6001 2.12573 10.6001C3.03431 10.6001 3.66858 11.2834 3.66858 12.0001C3.66858 12.7168 3.03431 13.4001 2.12573 13.4001C1.61447 13.4001 1.20001 13.818 1.20001 14.3334V14.8001C1.20001 16.6685 1.4253 18.2174 2.48002 19.2143C3.51343 20.1911 5.10157 20.4001 7.06287 20.4001H16.9372C18.8985 20.4001 20.4866 20.1911 21.52 19.2143C22.5747 18.2174 22.8 16.6685 22.8 14.8001C22.8 14.2846 22.3856 13.8668 21.8743 13.8668C20.9657 13.8668 20.3314 13.1835 20.3314 12.4668C20.3314 11.7501 20.9657 11.0668 21.8743 11.0668C22.3856 11.0668 22.8 10.6489 22.8 10.1334V9.2001C22.8 7.33171 22.5747 5.7828 21.52 4.78586C20.4866 3.80907 18.8985 3.6001 16.9372 3.6001H7.06287ZM8.60573 5.46676V6.4001C8.60573 6.91556 9.02018 7.33343 9.53144 7.33343C10.0427 7.33343 10.4572 6.91556 10.4572 6.4001V5.46676H16.9372C18.9256 5.46676 19.806 5.72446 20.2537 6.14767C20.6802 6.55073 20.9486 7.33515 20.9486 9.2001V9.32458C19.5532 9.70715 18.48 10.9332 18.48 12.4668C18.48 13.9931 19.5432 15.2149 20.929 15.6035C20.8601 16.9027 20.613 17.5129 20.2537 17.8525C19.806 18.2757 18.9256 18.5334 16.9372 18.5334H10.4572V17.6001C10.4572 17.0846 10.0427 16.6668 9.53144 16.6668C9.02018 16.6668 8.60573 17.0846 8.60573 17.6001V18.5334H7.06287C5.07446 18.5334 4.19402 18.2757 3.74629 17.8525C3.34686 17.475 3.08605 16.7628 3.05464 15.1414C4.44848 14.7578 5.52001 13.5324 5.52001 12.0001C5.52001 10.4677 4.44848 9.24236 3.05464 8.85879C3.08605 7.23738 3.34686 6.52521 3.74629 6.14767C4.19402 5.72446 5.07446 5.46676 7.06287 5.46676H8.60573Z'
        fill='#00A7F0'
      />
    </svg>
  );
};

export default Ticket;
