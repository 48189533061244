import React, { useEffect, useState } from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useDispatch, useSelector } from "react-redux"
import * as dashboardActions from '../../reducers/dashboard/actions'

import { IonSpinner, IonGrid, IonRow, IonCol, IonLabel, IonList, IonItem, IonCard, IonCardContent, IonCardHeader, IonIcon, IonButton} from "@ionic/react"
import { chevronBack  } from 'ionicons/icons';

ChartJS.register(ArcElement, Tooltip, Legend);


const Dashboard = () => {

    const dispatch = useDispatch()

    const {dashData, loading, evento_nome } = useSelector( state => ({
        dashData: state.dashboard.data, 
        loading: state.dashboard.loading, 
        evento_nome: state.user?.evento?.nome || ""
    }) )

    const [detail, setDetail] = useState(null)

    const getData = () => {setDetail(null); dispatch(dashboardActions.get())}
    const clear = () => dispatch(dashboardActions.clear())

    const chartDataFormatter = (data) => {
        return {
            labels: ['Ausentes', 'Presentes'], 
            datasets : [
                {
                    label: 'Participação', 
                    data: [data.total - data.checked, data.checked], 
                    backgroundColor: [
                        '#CCC',
                        '#09F',          
                    ],
                    borderColor: [
                        '#bbb',
                        '#09C',          
                    ],
                    borderWidth: 1,

                }
            ]
        }
    }

    useEffect(() => {
        getData()

        return () =>  clear()
    }, [])

    
    return (
        loading ? 
        <div style={{display: 'flex', width: '100%', height:'300px', alignItems: 'center', justifyContent: 'center'}}>
            <h1>
            <IonSpinner name="crescent" />
            </h1>
        </div> :
        detail ? 
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonCard>
                    <IonButton onClick={() => setDetail(null)} expand="block" color="light"><IonIcon icon={chevronBack} slot="start"></IonIcon> Voltar</IonButton>
                        <IonCardHeader>
                            
                        <h2 style={{textAlign:'center', margin: '0'}}>{ detail.nome }</h2>
                                <p style={{textAlign:'center', margin: '0'}}>{ detail.checked }/{ detail.bilhete_qt > 0 ? detail.bilhete_qt : detail.geral  }</p>
                            </IonCardHeader>
                        <IonCardContent>
                            <Doughnut data={chartDataFormatter(detail)} options={{maintainAspectRatio: false}} />
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid> :    
        dashData.general ?
        <IonGrid>        
            <IonRow>
                <IonCol>                        
                    <IonCard>
                        <IonCardHeader>
                        <h2 style={{textAlign:'center', margin: '0'}}>Total</h2>
                                <p style={{textAlign:'center', margin: '0'}}>{ dashData.general.checked }/{ dashData.general.total }</p>
                            </IonCardHeader>
                        <IonCardContent>
                            <Doughnut data={chartDataFormatter(dashData.general)} options={{maintainAspectRatio: false}} />
                        </IonCardContent>
                    </IonCard>
                </IonCol>    
            </IonRow>
            <IonRow>
                {
                    (dashData?.tickets || []).map((data, idx) => (
                        <IonCol size="6" key={idx}>
                            <IonCard button onClick={() => setDetail(data)}>

                                
                                <IonList>
                                    <IonItem detail={false} lines="none">
                                        <IonLabel  className="ion-text-center">
                                            <h2>{ data.nome }</h2>
                                            <p>{data.checked}/{data.bilhete_qt > 0 ? data.bilhete_qt : data.geral }</p>
                                        </IonLabel>                            
                                    </IonItem>
                                </IonList>

                                <div style={{ marginBottom: '20px'}}>
                                    <Doughnut data={chartDataFormatter(data)} options={{maintainAspectRatio: false}} />
                                </div>
                            </IonCard>
                        </IonCol>
                    ))
                }  
                
            </IonRow>
        </IonGrid>
        :
        <IonGrid>
            <IonRow>
                <IonCol>
                    <h1 style={{textAlign: 'center'}}>Aguardando dados</h1>
                </IonCol>
            </IonRow>
        </IonGrid>    
        
        
    )
}


export default Dashboard
