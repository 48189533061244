import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Graph: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='32'
      height='33'
      viewBox='0 0 32 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M16.3885 11.1197C16.3885 9.18871 17.9319 7.56276 19.8856 7.56276C21.8394 7.56276 23.3828 9.18871 23.3828 11.1197V21.8801C23.3828 23.8111 21.8394 25.437 19.8856 25.437C17.9165 25.437 16.3885 23.8072 16.3885 21.8801V11.1197ZM19.8856 9.89419C19.2748 9.89419 18.7199 10.4203 18.7199 11.1197V21.8801C18.7199 22.5833 19.2669 23.1056 19.8856 23.1056C20.4965 23.1056 21.0513 22.5795 21.0513 21.8801V11.1197C21.0513 10.4203 20.4965 9.89419 19.8856 9.89419Z'
        fill='#00A7F0'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.1142 13.7799C10.1184 13.7799 8.61705 15.4929 8.61705 17.4325V21.7845C8.61705 23.7201 10.1031 25.437 12.1142 25.437C14.11 25.437 15.6113 23.724 15.6113 21.7845V17.4325C15.6113 15.4929 14.11 13.7799 12.1142 13.7799ZM10.9485 17.4325C10.9485 16.6365 11.5454 16.1113 12.1142 16.1113C12.6829 16.1113 13.2799 16.6365 13.2799 17.4325V21.7845C13.2799 22.5805 12.6829 23.1056 12.1142 23.1056C11.5374 23.1056 10.9485 22.5844 10.9485 21.7845V17.4325Z'
        fill='#00A7F0'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.7619 5.2619C6.48634 3.53747 9.04607 2.8999 12.2696 2.8999H19.7302C22.9537 2.8999 25.5135 3.53747 27.2379 5.2619C28.9623 6.98634 29.5999 9.54607 29.5999 12.7696V20.2302C29.5999 23.4537 28.9623 26.0135 27.2379 27.7379C25.5135 29.4623 22.9537 30.0999 19.7302 30.0999H12.2696C9.04607 30.0999 6.48634 29.4623 4.7619 27.7379C3.03747 26.0135 2.3999 23.4537 2.3999 20.2302V12.7696C2.3999 9.54607 3.03747 6.98634 4.7619 5.2619ZM6.41047 6.91047C5.33719 7.98376 4.73133 9.77602 4.73133 12.7696V20.2302C4.73133 23.2238 5.33719 25.0161 6.41047 26.0893C7.48376 27.1626 9.27602 27.7685 12.2696 27.7685H19.7302C22.7238 27.7685 24.5161 27.1626 25.5893 26.0893C26.6626 25.0161 27.2685 23.2238 27.2685 20.2302V12.7696C27.2685 9.77602 26.6626 7.98376 25.5893 6.91047C24.5161 5.83719 22.7238 5.23133 19.7302 5.23133H12.2696C9.27602 5.23133 7.48376 5.83719 6.41047 6.91047Z'
        fill='#00A7F0'
      />
    </svg>
  );
};

export default Graph;
