import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { applyMiddleware, compose } from 'redux';
//import thunk from 'redux-thunk';
import logger from 'redux-logger';
import {
  createMigrate,
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
//import { createFilter } from 'redux-persist-transform-filter';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/root-saga';

import rootReducer from './reducers/rootReducer';

const migrations = {
  // initial version 0: we will clean up all historical data
  // from local storage for the time
  // before we began with migration versioning
  0: (state) => {
    state = {};

    return { ...state };
  },
  // Next version
  //	1 : (state) =>
  //	{
  //		return { ...state };
  //	}
};

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
};

const sagaMiddleware = createSagaMiddleware();

const reduxMiddlewares = [sagaMiddleware];

const pReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

const store = configureStore({
  reducer: pReducer,

  devTools: true,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: true,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    sagaMiddleware,
  ],
  rootReducer,
});

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
