import React from 'react';
import PageLayout from '../../components/layout/Page';
import Form from './form';
import { closeOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import * as attendeesActions from './../../reducers/attendees/actions';
import { useSelector } from 'react-redux';
import NewForm from './form/NewForm';
import { Box } from '../../components/ui';
import ArrowBack from '../../components/icons/ArrowBack';
import styles from './updateAttendee.module.css';

const UpdateAttendee = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { attendee, loading } = useSelector((state: any) => state.attendees);

  const setAttendee = (attendee: any) =>
    dispatch(attendeesActions.attendee(attendee));

  const handleNavButtonClick = () => {
    history.push(attendee.redirect);
    setTimeout(() => {
      setAttendee({});
    }, 600);
  };

  return (
    <PageLayout
      pageId='update-attende-page'
      customIcon={
        <Box className={styles.menuBox} onClick={() => handleNavButtonClick()}>
          <ArrowBack className={styles.menuIcon} />
        </Box>
      }
      showNavbutton={false}
    >
      <NewForm />
    </PageLayout>
  );
};

export default UpdateAttendee;
