import React, { useState, useRef, useEffect } from 'react';
//import  QrReader from 'react-qr-scanner'
import { QrReader } from 'react-qr-reader';
import { useDispatch, useSelector } from 'react-redux';
import * as attendeesActions from '../../reducers/attendees/actions';

import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonHeader,
  useIonViewDidEnter,
  useIonViewWillLeave,
  IonSpinner,
} from '@ionic/react';
import { scan, closeCircle } from 'ionicons/icons';

import './Scanner.scss';
import CheckinStatus from './CheckinStatus';
import AttendeeDetails from '../../pages/attendee/AttendeeDetails';

const Scanner = () => {
  const dispatch = useDispatch();
  const { draft, loading: loadingContacts } = useSelector(
    (state) => state.contacts
  );

  const { errorMessage, state, errors } = useSelector((state) => ({
    errors: state.notification.errors,
    errorMessage: state.notification.message,
    state: state,
  }));

  const { nome, nome_ingresso, cpf, codigo } = errors || {};

  const sendScan = (codigo) => dispatch(attendeesActions.scan(codigo));
  const [data, setData] = useState('');

  const clearList = () => {
    setTimeout(() => {
      setData(null);
    }, 60000);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        sendScan(data);
      }
    };

    fetchData();

    // Cleanup function
    return () => clearList();
  }, [data]);

  const [redirected, setRedirected] = useState(false);

  const setAttendee = (attendee) =>
    dispatch(attendeesActions.attendee(attendee));

  useEffect(() => {
    const handleRedirect = async () => {
      if (
        errorMessage ===
          'CPF inválido! Por favor, corrija os números e tente novamente.' &&
        !redirected
      ) {
        setRedirected(true);
        await setAttendee(errors);
      }
    };

    handleRedirect();

    //return () => clearList();
  }, [errorMessage, redirected]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {redirected ? (
        ''
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {loadingContacts ? (
              <h1 style={{ textAlign: 'center' }}>
                <IonSpinner />
              </h1>
            ) : (
              <>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    minHeight: '260px',
                    maxHeight: '340px',
                  }}
                  className='shadow-wrapper'
                >
                  <img
                    src={'/assets/icon/qrcode-target.svg'}
                    style={{
                      position: 'absolute',
                      width: '140px',
                      height: '140px',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 9999,
                    }}
                    alt='qrcode-target'
                  />
                  <QrReader
                    className='qr-reader'
                    scanDelay={1}
                    onResult={(result, error) => {
                      if (!!result) {
                        const text = result?.text || '';

                        if (text.trim() !== '') {
                          setData(text.trim());
                        }
                      }
                    }}
                    constraints={{ facingMode: 'environment' }}
                    videoStyle={{
                      width: '100%',
                      aspectRatio: '1',
                      objectFit: 'cover',
                      minHeight: '260px',
                      maxHeight: '340px',
                    }}
                  />
                </div>

                {/* caso o participante não tenha realizado o checking */}
                {errorMessage ===
                'Chek-in não realizado! O participante já efetuou o check-in.' ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      marginTop: '10px',
                    }}
                  >
                    <CheckinStatus
                      status='error'
                      errorMessage='Ingresso já utilizado!'
                      codigo={codigo}
                      cpf={cpf}
                      nome={nome}
                      nome_ingresso={nome_ingresso}
                    />
                  </div>
                ) : errorMessage ===
                  'Chek-in não realizado! Participante cancelado.' ? (
                  <>
                    <CheckinStatus
                      status='error'
                      errorMessage='Ingresso cancelado!'
                      codigo={codigo}
                      cpf={cpf}
                      nome={nome}
                      nome_ingresso={nome_ingresso}
                    />
                  </>
                ) : errorMessage ===
                  'Chek-in não realizado! Ingresso inválido ou com checkin efetivado.' ? (
                  <>
                    <CheckinStatus
                      status='error'
                      errorMessage='CPF inválido! Por favor, corrija os números e tente novamente.'
                      codigo={codigo}
                      cpf={cpf}
                      nome={nome}
                      nome_ingresso={nome_ingresso}
                    />
                  </>
                ) : errorMessage === 'Sucesso na sua solicitão' ? (
                  <>
                    <CheckinStatus
                      status='success'
                      errorMessage='Sucesso na sua solicitão'
                      codigo={codigo}
                      cpf={cpf}
                      nome={nome}
                      nome_ingresso={nome_ingresso}
                    />
                  </>
                ) : (
                  <h1
                    style={{
                      textAlign: 'center',
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      width: '336px',
                    }}
                  >
                    Coloque o leitor sobre o QR Code. A leitura será feita
                    automaticamente.
                  </h1>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Scanner;
