import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as attendeesActions from '../../reducers/attendees/actions';

import {
  IonCard,
  IonCardContent,
  IonItem,
  IonList,
  IonAvatar,
  IonLabel,
  IonIcon,
  IonBadge,
} from '@ionic/react';
import { checkmark, lockClosed, pricetagOutline } from 'ionicons/icons';

const AttendeeTile = ({ info }) => {
  const dispatch = useDispatch();

  const evento_nome = useSelector((state) => state.user?.evento?.nome || '');

  const setAttendee = (attendee) =>
    dispatch(attendeesActions.attendee(attendee));

  return (
    <IonCard className='attendee-tile' button onClick={() => setAttendee(info)}>
      <IonList>
        <IonItem detail={false} className='attendee-item'>
          {info.data_credenciamento || info.cancelado ? (
            <IonIcon icon={lockClosed} slot='start'></IonIcon>
          ) : (
            <IonIcon icon={checkmark} slot='start'></IonIcon>
          )}

          <IonLabel>
            <h2>{info.nome}</h2>
            <p>
              <b>{info.codigo}</b>
            </p>
            <p>
              <b>{info.cpf}</b>
            </p>
          </IonLabel>
          {info.data_credenciamento && (
            <IonBadge slot='end' color='success'>
              Registrado
            </IonBadge>
          )}
          {info.cancelado && (
            <IonBadge slot='end' color='danger'>
              Cancelado
            </IonBadge>
          )}
        </IonItem>
        <IonItem lines='none'>
          <IonIcon icon={pricetagOutline} slot='start'></IonIcon>
          <IonLabel textCenter>
            <p>
              <b>{info.nome_bilhete}</b>
            </p>
          </IonLabel>
        </IonItem>
      </IonList>
    </IonCard>
  );
};

export default AttendeeTile;
