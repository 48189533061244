import React, { useEffect, useState } from 'react';
import styles from './form.module.css';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import * as attendeesActions from '../../../reducers/attendees/actions';

import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonIcon,
  IonBadge,
  IonCard,
  IonButton,
  IonSpinner,
  IonInput,
} from '@ionic/react';
import {
  lockClosed,
  alertCircle,
  qrCode,
  pricetagOutline,
} from 'ionicons/icons';

import { isEmailInvalid, isPhoneInvalid, validarCPF } from './validation';
import { cpf_mask, fone_mask } from './mask';
import Container from '../../../components/ui/container';
import { Box, Button, Input, Typography } from '../../../components/ui';
import QrCode from '../../../components/icons/QrCode';
import Ticket from '../../../components/icons/Ticket';
import Tag from '../../../components/ui/tag';
import { useAlert } from '../../../context/AlertContext';
import CorrectCircle from '../../../components/icons/CorrectCircle';
import Warning from '../../../components/icons/Warning';
import AlertWarning from '../../../components/icons/AlertWarning';
import { useHistory, useLocation } from 'react-router';

const NewForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector((state: any) => state.attendees.loading);

  const { attendee: attendeeCheckin } = useSelector(
    (state: any) => state.attendees
  );

  const [cpfError, setCpfError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [telefoneError, setTelefoneError] = useState('');
  const [nomeError, setNomeError] = useState('');

  const isFoneInvalido = (attendee: any) => {
    return attendee.telefone && attendee.telefone.length < 9;
  };

  const validarForm = (attendee: any) => {
    let isValid = true;
    if (!validarCPF(attendee.cpf)) {
      setCpfError('CPF inválido. Por favor, insira um CPF válido.');
      isValid = false;
    } else {
      setCpfError('');
    }
    if (isEmailInvalid(attendee.email)) {
      setEmailError('Email inválido. Por favor, insira um email válido.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (isPhoneInvalid(attendee.telefone)) {
      setTelefoneError(
        'Telefone inválido. Por favor, insira um número de telefone válido.'
      );
      isValid = false;
    } else {
      setTelefoneError('');
    }

    if (!attendee.nome.trim()) {
      setNomeError('Nome é obrigatório. Por favor, preencha o campo nome.');
      isValid = false;
    } else {
      setNomeError('');
    }

    return isValid;
  };

  const checkin = (attendee: any) => {
    if (validarForm(attendee)) {
      dispatch(attendeesActions.checkin(attendee));
      setTimeout(() => {
        history.push(attendeeCheckin.redirect);
      }, 400);
    }
  };

  const [error, setError] = useState('');

  const [attendee, setAttendee] = useState({
    ...attendeeCheckin,
    cpf: validarCPF(attendeeCheckin.cpf) ? attendeeCheckin.cpf : '',
    email: !isEmailInvalid(attendeeCheckin) ? attendeeCheckin.email : '',
    telefone: !isFoneInvalido(attendeeCheckin) ? attendeeCheckin.telefone : '',
  });

  useEffect(() => {
    setAttendee({
      ...attendeeCheckin,
      cpf: validarCPF(attendeeCheckin.cpf) ? attendeeCheckin.cpf : '',
      //email: !isEmailInvalid(attendeeCheckin) ? attendeeCheckin.email : '',
      telefone: !isFoneInvalido(attendeeCheckin)
        ? attendeeCheckin.telefone
        : '',
    });
  }, [attendeeCheckin]);

  const { showAlert, hideAlert } = useAlert();

  useEffect(() => {
    if (attendee.cancelado) {
      showAlert('Ingresso cancelado!', 'error', false, <AlertWarning />);
    }
    if (attendee.data_credenciamento) {
      showAlert(
        'Participante já credenciado',
        'warning',
        false,
        <CorrectCircle />,
        `Check-In realizado no dia ${attendee.data_credenciamento}. Em caso de problema peça para o participante obter orientação com a organização do evento.`
      );
    }

    if (!attendee.data_credenciamento && !attendee.cancelado) {
      hideAlert();
    }
  }, [attendee.cancelado, attendee.data_credenciamento]);

  useEffect(() => {
    const resetErrorOnRouteChange = () => {
      setError('');
      setCpfError('');
      setEmailError('');
      setTelefoneError('');
      setNomeError('');
    };

    window.addEventListener('hashchange', resetErrorOnRouteChange);

    return () => {
      window.removeEventListener('hashchange', resetErrorOnRouteChange);
    };
  }, []);

  return (
    <Container className={styles.container}>
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <Typography className={styles.title}>Dados do participante</Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            position: 'relative',
          }}
        >
          <Tag
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              backgroundColor: attendee.data_credenciamento
                ? '#F7F9FA'
                : attendee.cancelado
                ? '#EA4949'
                : '#53A462',
              color: attendee.data_credenciamento
                ? 'rgba(34, 34, 34, 0.56)'
                : '#fafafa',
            }}
          >
            {attendee.data_credenciamento ? (
              'Registrado'
            ) : (
              <>{attendee.cancelado ? 'Cancelado' : 'Válido'}</>
            )}
          </Tag>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <QrCode />
            <Typography className={styles.iconText}>
              {attendee.codigo}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <Ticket />
            <Typography className={styles.iconText}>
              {attendee.nome_ingresso
                ? attendee.nome_ingresso
                : attendee.nome_bilhete}
            </Typography>
          </Box>
        </Box>
        {/* inputs */}
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Input
            label='cpf *'
            placeholder='Insira o cpf '
            value={cpf_mask(attendee.cpf)}
            mask='cpf'
            onChange={(e) => {
              setAttendee({ ...attendee, cpf: e.target.value });
            }}
            warning={cpfError}
            disabled={
              attendee.data_credenciamento || attendee.cancelado ? true : false
            }
          />
          <Input
            label='nome *'
            placeholder='Insira o nome '
            value={attendee.nome}
            onChange={(e) => {
              setAttendee({ ...attendee, nome: e.target.value });
            }}
            warning={nomeError}
            disabled={
              attendee.data_credenciamento || attendee.cancelado ? true : false
            }
          />
          <Input
            label='email *'
            placeholder='Insira o email '
            value={attendee.email}
            onChange={(e) => {
              setAttendee({ ...attendee, email: e.target.value });
            }}
            warning={emailError}
            disabled={
              attendee.data_credenciamento || attendee.cancelado ? true : false
            }
          />
          <Input
            label='Telefone *'
            placeholder='Insira o telefone '
            value={fone_mask(attendee?.telefone || '')}
            onChange={(e) => {
              setAttendee({ ...attendee, telefone: e.target.value });
            }}
            maxLength={15}
            warning={telefoneError}
            disabled={
              attendee.data_credenciamento || attendee.cancelado ? true : false
            }
          />
        </Box>
        {attendee.data_credenciamento || attendee.cancelado ? null : (
          <>
            <Button
              label='Fazer Check-In'
              onClick={() => checkin(attendee)}
              loading={loading}
            />
            {error && (
              <Typography
                style={{
                  color: '#ff0000',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                {error}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default NewForm;
