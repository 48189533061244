import React, { CSSProperties, ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const Container: React.FC<ContainerProps> = ({
  children,
  style,
  className,
}) => {
  const defaultStyle: CSSProperties = {
    width: '100%',
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  return (
    <div style={{ ...defaultStyle, ...style }} className={className}>
      {children}
    </div>
  );
};

export default Container;
