import * as actionTypes from './actionTypes';

export const add = (message) => ({
  type: actionTypes.ADD,
  payload: {
    message,
    type: 'default',
  },
});

export const info = (message) => ({
  type: actionTypes.INFO,
  payload: {
    message,
    type: 'info',
  },
});

export const success = (message, errors) => ({
  type: actionTypes.SUCCESS,
  payload: {
    message,
    errors,
    type: 'success',
  },
});

export const warning = (message) => ({
  type: actionTypes.WARNING,
  payload: {
    message,
    type: 'warning',
  },
});

export const error = (message, errors) => ({
  type: actionTypes.ERROR,
  payload: {
    message,
    errors,
    type: 'error',
  },
});

export const clear = () => ({
  type: actionTypes.CLEAR,
});
