// Button.tsx
import React from 'react';
import styles from './button.module.css';
import { IonSpinner } from '@ionic/react';

interface ButtonProps {
  label?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  className,
  disabled = false,
  loading = false,
}) => {
  return (
    <button
      className={`${className} ${styles.button}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? (
        <>
          <IonSpinner name='dots'></IonSpinner>
        </>
      ) : (
        label
      )}
    </button>
  );
};

export default Button;
