import { useState } from 'react';

type FocusHandlers = {
  onFocus: () => void;
  onBlur: () => void;
};

// Explicitly define the return type of the hook
const useFocus = (): [boolean, FocusHandlers] => {
  const [isFocused, setIsFocused] = useState(false);
  const bind: FocusHandlers = {
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
  };
  return [isFocused, bind];
};

export default useFocus;
