import * as actionTypes from './actionTypes';

import * as userActionTypes from './../user/actionTypes';

const initialState = {
  results: [],
  attendee: {},
  loading: false,
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESULTS: {
      const { results } = action.payload;

      return {
        ...state,
        results,
      };
    }

    case actionTypes.ATTENDEE: {
      const { attendee } = action.payload;

      return {
        ...state,
        attendee: attendee,
      };
    }

    case actionTypes.ERRORS: {
      const { errors } = action.payload;

      return {
        ...state,
        errors,
      };
    }

    case actionTypes.LOADING: {
      const { flag } = action.payload;

      return {
        ...state,
        loading: flag,
      };
    }

    case userActionTypes.LOGOFF:
    case actionTypes.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reducer;
