import { useState, useEffect } from 'react';

type Breakpoints = {
  [key: number]: string;
};

const useResponsiveStyles = (values: string[]) => {
  const breakpoints: Breakpoints = {
    640: values[0],
    768: values[1],
    1024: values[2],
    1280: values[3],
    1536: values[4],
  };

  // Initial color based on current window width
  const getColor = (width: number): string => {
    if (width < 768) return breakpoints[640];
    else if (width < 1024) return breakpoints[768];
    else if (width < 1280) return breakpoints[1024];
    else if (width < 1536) return breakpoints[1280];
    else return breakpoints[1536];
  };

  const [color, setColor] = useState<string>(getColor(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setColor(getColor(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return color;
};

export default useResponsiveStyles;
