import React, { useState } from 'react';
import PageLayout from '../../components/layout/Page';
import { qrCode } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import * as attendeesActions from '../../reducers/attendees/actions';
import { IonSpinner } from '@ionic/react';
import { Box, Typography } from '../../components/ui';
import { useSelector } from 'react-redux';
import Container from '../../components/ui/container';
import styles from './searchAttendee.module.css';
import AttendesList from './attendeesList';
import SearchBar from './searchBar';
import Skeleton from '../../components/ui/skeleton';

const SearchAttendee = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { loading } = useSelector((state: any) => ({
    loading: state.attendees.loading,
    results: state.attendees.results.length,
    attendee: state.attendees.attendee,
    evento_nome: state.user?.evento?.nome || '',
  }));

  const handleNavButtonClick = () => {
    history.push('/checkin');
    dispatch(attendeesActions.clear());
  };

  return (
    <PageLayout
      pageId='search-attende-page'
      navButtonProps={{
        onNavButtonClick: handleNavButtonClick,
        color: 'primary',
        icon: qrCode,
      }}
    >
      <Container className={styles.container}>
        <SearchBar />

        {loading ? (
          <Box className={styles.loadingBox}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton
                key={index}
                height='110px'
                width='100%'
                borderRadius='4px'
              />
            ))}
          </Box>
        ) : (
          <AttendesList />
        )}
      </Container>
    </PageLayout>
  );
};

export default SearchAttendee;
