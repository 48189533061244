import * as actionTypes from './actionTypes'

export const add = (contact) => ({
    type: actionTypes.ADD,
    payload : { contact }
})

export const addSaga = (contact) => ({
    type: actionTypes.ADD_SAGA,
    payload : contact 
})

export const scan = (qrcode) => ({
    type: actionTypes.SCAN,
    payload : { qrcode }
}) 

export const addDraft = (draft) => ({
    type: actionTypes.ADD_DRAFT,
    payload : { draft }
}) 

export const clearDraft = () => ({
    type: actionTypes.CLEAR_DRAFT,    
}) 

export const setLoading = (flag) => ({
    type: actionTypes.SET_LOADING,  
    payload: { flag }  
}) 

export const getVcf = (id) => ({
    type: actionTypes.GET_VCF,  
    payload: { id }  
}) 