import React from 'react';
import { IonButtons, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import { Box, Image } from '../../ui';
import Menu from './menu';
import styles from './navbar.module.css';
import { Link } from 'react-router-dom';

export interface NavbarProps {
  customIcon?: React.ReactNode;
}

const Navbar: React.FC<NavbarProps> = ({ customIcon }) => {
  return (
    <Box className={styles.navbarBox}>
      {customIcon || <Menu />}
      <Link to='/checkin'>
        <Image
          src='/assets/icon/logo_ticket.png'
          alt='logo-ticket'
          className={styles.navbarLogo}
        />
      </Link>
    </Box>
  );
};

export default Navbar;
