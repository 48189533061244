// Layout.tsx
import React, { ReactNode, useState } from 'react';

import {
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Container from '../ui/container';
import { Box, Image } from '../ui';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import * as dashboardActions from '../../reducers/dashboard/actions';
import * as attendeesActions from '../../reducers/attendees/actions';
import {
  closeOutline,
  qrCode as qrCodeIcon,
  search as searchIcon,
} from 'ionicons/icons';

export interface NavButtonProps {
  pageId?: string;
  onNavButtonClick?: () => void;
  color?: string;
  icon?: any;
}

const NavButton: React.FC<NavButtonProps> = ({
  pageId,
  onNavButtonClick,
  icon,
  color = 'danger',
}) => {
  return (
    <>
      <IonFab
        vertical='bottom'
        horizontal='end'
        slot='fixed'
        style={{ marginRight: '10px', marginBottom: '8px', zIndex: 2 }}
      >
        <IonFabButton onClick={onNavButtonClick} color={color}>
          <IonIcon icon={icon} />
        </IonFabButton>
      </IonFab>

      {/*{pageId === 'checkin-page' ? (
        <IonFab vertical='bottom' horizontal='end' slot='fixed'>
          <IonFabButton
            onClick={() => {
              if (!loading) getData();
            }}
            color='success'
          >
            {loading ? (
              <IonSpinner></IonSpinner>
            ) : (
              <IonIcon icon={refreshCircleOutline} />
            )}
          </IonFabButton>
        </IonFab>
      ) : hasActiveOperation ? (
        <IonFab vertical='bottom' horizontal='end' slot='fixed'>
          <IonFabButton onClick={() => clear()} color='danger'>
            <IonIcon icon={closeOutline} />
          </IonFabButton>
        </IonFab>
      ) : (
        <IonFab vertical='bottom' horizontal='end' slot='fixed'>
          <IonFabButton onClick={() => setShowScanner(!showScanner)}>
            <IonIcon icon={showScanner ? searchIcon : qrCodeIcon} />
          </IonFabButton>
        </IonFab>
      )}*/}
    </>
  );
};

export default NavButton;
