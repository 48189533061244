import React, { useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';

import { useSelector } from 'react-redux';
import AttendeeCard from './AttendeeCard';
import { Box, Typography } from '../../../components/ui';
import styles from './attendeesList.module.css';
import Opps from '../../../components/icons/Opps';

const AttendesList: React.FC = () => {
  const { results } = useSelector((state: any) => state.attendees);
  const { errorMessage, errors } = useSelector((state: any) => ({
    errors: state.notification.errors,
    errorMessage: state.notification.message,
    state: state,
  }));
  return (
    <IonGrid fixed style={{ padding: '0px', marginTop: '24px' }}>
      <IonRow>
        {errorMessage === 'Participantes não localizados' ? (
          <Box className={styles.boxNotFound}>
            <Opps />
            <Typography className={styles.textNotFound}>
              {errorMessage}
            </Typography>
          </Box>
        ) : (
          <>
            {results.map((attendee?: any, idx?: any) => (
              <IonCol
                style={{ padding: '0px' }}
                size='12'
                size-md='12'
                key={idx}
              >
                <AttendeeCard {...attendee} />
              </IonCol>
            ))}
          </>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default AttendesList;
