import * as actionTypes from './actionTypes'

const initialState = {    
    scans : [], 
    draft: {}, 
    loading: false
}

const reducer = (state = initialState, action ) => {
    switch (action.type){
        case actionTypes.ADD: {

            const  { contact }  = action.payload

            return {
                ...state, 
                scans: state.scans.concat(contact), 
                draft: {},
            }
        }

        case actionTypes.ADD_DRAFT: {

            const  { draft }  = action.payload

            return {
                ...state, 
                draft
            }
        }

        case actionTypes.SET_LOADING: {

            const  { flag }  = action.payload

            return {
                ...state, 
                loading: flag
            }
        }

        case actionTypes.CLEAR_DRAFT: {

            

            return {
                ...state, 
                draft : {}
            }
        }

        default: return state
    }
}

export default reducer