import * as actionTypes from './actionTypes'

const initialState = {    
    id: null, 
    name: "",
    email: "", 
    isLogged: false, 
    loading: false
}

const reducer = (state = initialState, action ) => {
    switch (action.type){
        case actionTypes.SET: {

            const  { user }  = action.payload

            return {
                ...state, 
                ...user 
            }
        }

        case actionTypes.SET_LOADING: {

            const  { flag }  = action.payload

            return {
                ...state, 
                loading: flag 
            }
        }

        case actionTypes.LOGOFF: {
            return initialState
        }

        default: return state
    }
}

export default reducer