import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

const HomeOrLogin = ({ match }) => {
  const isLogged = useSelector((state) => state.user.isLogged);

  return isLogged ? (
    <Redirect to={`/checkin`} />
  ) : (
    <Redirect to={`/auth/login`} />
  );
};

export default HomeOrLogin;
