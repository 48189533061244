import React, { CSSProperties, ReactNode } from 'react';
import styles from './tag.module.css';
import Typography from '../typography';

type ImageProps = {
  className?: string;
  onClick?: () => void;
  width?: number | string;
  height?: number | string;
  style?: CSSProperties;
  children?: ReactNode;
};

const Tag: React.FC<ImageProps> = ({
  className,
  onClick,
  width,
  height,
  style,
  children,
}) => {
  return (
    <Typography className={styles.tag} style={style}>
      {children}
    </Typography>
  );
};

export default Tag;
