import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  color?: string;
}

const SearchIcon: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
  color = '#222222',
}) => {
  return (
    <svg
      className={className}
      style={style}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12 1.19995C6.03533 1.19995 1.20001 6.03527 1.20001 12C1.20001 17.9646 6.03533 22.8 12 22.8C17.9647 22.8 22.8 17.9646 22.8 12C22.8 6.03527 17.9647 1.19995 12 1.19995ZM3.06924 12C3.06924 7.06762 7.06768 3.06918 12 3.06918C16.9323 3.06918 20.9308 7.06762 20.9308 12C20.9308 16.9323 16.9323 20.9307 12 20.9307C7.06768 20.9307 3.06924 16.9323 3.06924 12ZM21.9364 20.6636C21.5849 20.3121 21.0151 20.3121 20.6636 20.6636C20.3121 21.015 20.3121 21.5849 20.6636 21.9363L22.6636 23.9363C23.0151 24.2878 23.5849 24.2878 23.9364 23.9363C24.2879 23.5849 24.2879 23.015 23.9364 22.6636L21.9364 20.6636Z'
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
