import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const ArrowBack: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M20.36 24.6813C20.8286 24.2127 20.8286 23.4529 20.36 22.9842L13.9117 16.536C13.6188 16.2431 13.6188 15.7567 13.9117 15.4638L20.36 9.01557C20.8286 8.54695 20.8286 7.78715 20.36 7.31852C19.8913 6.84989 19.1315 6.84988 18.6629 7.31851L12.2146 13.7668C10.9845 14.9969 10.9845 17.0029 12.2146 18.233L18.6629 24.6813C19.1315 25.1499 19.8913 25.1499 20.36 24.6813Z'
        fill='#222222'
      />
    </svg>
  );
};

export default ArrowBack;
