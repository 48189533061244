import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import * as dashboardActions from './../../reducers/dashboard/actions';
import * as attendeesActions from './../../reducers/attendees/actions';

import Checkin from './Checkin';

import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  IonIcon,
  IonHeader,
  useIonViewDidEnter,
  IonSearchbar,
  IonFab,
  IonFabButton,
  IonSpinner,
  getConfig,
} from '@ionic/react';
import {
  qrCode as qrCodeIcon,
  search as searchIcon,
  closeOutline,
  refreshCircleOutline,
} from 'ionicons/icons';

import './HomePage.scss';
import Dashboard from './Dashboard';
import Scanner from '../../components/scanner/Scanner';

const HomePage = () => {
  const dispatch = useDispatch();
  const hasActiveOperation = useSelector(
    (state) => state.attendees.results.length > 0 || state.attendees.attendee.id
  );
  const { imagem, banner, evento_nome } = useSelector((state) => ({
    banner: state.user?.evento?.banner || '',
    imagem:
      (state.user?.evento?.imagem || '').indexOf('https://') === 0
        ? state.user?.evento?.imagem
        : (state.user?.evento?.imagem || '').length > 0
        ? `https://api.ticketandgo.com.br/media/${state.user?.evento?.imagem}`
        : '',
    evento_nome: state.user?.evento?.nome || '',
  }));
  const loading = useSelector((state) => state.dashboard.loading);
  const isLogged = useSelector((state) => state.user.isLogged);

  const [segment, setSegment] = useState('checkin');
  const [showScanner, setShowScanner] = useState(true);

  const getData = () => {
    dispatch(dashboardActions.get());
  };
  const clear = () => dispatch(attendeesActions.clear());

  return isLogged ? (
    <IonPage id='home-page'>
      <IonContent fullscreen={true}>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={'/assets/icon/logo_ticket.png'}
              width='126px'
              height='26px'
              alt='logo-ticket'
              style={{ marginTop: '8px', marginLeft: '18px' }}
            />
          </IonTitle>
        </IonToolbar>

        <div
        //className='main-image'
        // style={{
        //   backgroundImage: imagem
        //     ? `url(${imagem})`
        //     : 'url("/assets/img/speakers/party.jpeg")',
        //   backgroundSize: 'revert',
        // }}
        >
          {/* <img
            src={imagem ? imagem : '/assets/img/speakers/party.jpeg'}
            alt=""
            style={{ backgroundSize: 'revert' }}
          /> */}
          <div>
            <h3
              style={{
                // position: 'absolute',
                // bottom: '0',
                overflow: 'hidden',
                color: '#111',
                textOverflow: 'ellipsis',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                margin: '0px !important',
                padding: '11px 19px 14px 19px',
              }}
            >
              {evento_nome}
            </h3>
          </div>
        </div>

        {/*<IonSegment
          onIonChange={(e) => setSegment(e.detail.value)}
          value={segment}
        >
          <IonSegmentButton value='checkin'>
            <IonLabel>Checkin</IonLabel>
          </IonSegmentButton>

          <IonSegmentButton value='dashboard'>
            <IonLabel>Dashboard</IonLabel>
          </IonSegmentButton>
        </IonSegment>*/}

        {segment === 'checkin' && !showScanner ? (
          <Checkin showScanner={showScanner} />
        ) : segment === 'checkin' && showScanner ? (
          <div>
            <Scanner />
            <Checkin showScanner={showScanner} />
          </div>
        ) : (
          <Dashboard />
        )}

        {/* função para dar reload quando é no dashboard */}
        {segment === 'dashboard' ? (
          <IonFab vertical='bottom' horizontal='end' slot='fixed'>
            <IonFabButton
              onClick={() => {
                if (!loading) getData();
              }}
              color='success'
            >
              {loading ? (
                <IonSpinner></IonSpinner>
              ) : (
                <IonIcon icon={refreshCircleOutline} />
              )}
            </IonFabButton>
          </IonFab>
        ) : hasActiveOperation ? (
          <IonFab vertical='bottom' horizontal='end' slot='fixed'>
            <IonFabButton onClick={() => clear()} color='danger'>
              <IonIcon icon={closeOutline} />
            </IonFabButton>
          </IonFab>
        ) : (
          <IonFab vertical='bottom' horizontal='end' slot='fixed'>
            <IonFabButton onClick={() => setShowScanner(!showScanner)}>
              <IonIcon icon={showScanner ? searchIcon : qrCodeIcon} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
    </IonPage>
  ) : (
    <Redirect to='/auth/login' />
  );
};

export default HomePage;
