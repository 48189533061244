// Typography.tsx
import React, { HTMLAttributes } from 'react';
import './Typography.css';

interface TypographyProps extends HTMLAttributes<HTMLElement> {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'small';
  children: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({
  tag = 'h1',
  children,
  className,
  ...props
}) => {
  const Component = tag;

  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
};

export default Typography;
