import React, { ChangeEvent, FC } from 'react';
import styles from './skeleton.module.css';
import Box from '../box';

interface SkeletonProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  style?: React.CSSProperties;
}

const Skeleton: FC<SkeletonProps> = ({
  width = '100%',
  height = '200px',
  borderRadius = '8px',
  style,
}) => {
  return (
    <Box
      style={{
        width: width,
        height: height,
        borderRadius: borderRadius,
        ...style,
      }}
      className={styles.loading}
    />
  );
};

export default Skeleton;
