import * as actionTypes from './actionTypes'

export const login = (code, pass) => ({
    type: actionTypes.LOGIN,
    payload : { code, pass }
}) 

export const logoff = () => ({
    type: actionTypes.LOGOFF,    
})

export const set = (user) => ({
    type: actionTypes.SET,    
    payload: { user }
})

export const setLoading = (flag) => ({
    type: actionTypes.SET_LOADING,    
    payload: { flag }
})