import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Xicon: React.FC<IconProps> = ({
  fill = '#222222',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      style={style}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.1645 8.10842C17.516 7.75695 17.516 7.1871 17.1645 6.83563C16.813 6.48416 16.2432 6.48415 15.8917 6.83562L12.0001 10.7272L8.10848 6.83562C7.757 6.48415 7.18715 6.48416 6.83568 6.83563C6.48421 7.1871 6.48422 7.75695 6.83569 8.10842L10.7273 12L6.83569 15.8916C6.48422 16.243 6.48421 16.8129 6.83568 17.1644C7.18715 17.5158 7.757 17.5158 8.10848 17.1644L12.0001 13.2728L15.8917 17.1644C16.2432 17.5158 16.813 17.5158 17.1645 17.1644C17.516 16.8129 17.516 16.243 17.1645 15.8916L13.2729 12L17.1645 8.10842Z'
        fill={fill}
        fill-opacity='0.56'
      />
    </svg>
  );
};

export default Xicon;
