import { combineReducers } from '@reduxjs/toolkit'

import  user from './user/reducer'
import  contacts from './contacts/reducer'
import  attendees from './attendees/reducer'
import  dashboard from './dashboard/reducer'
import  notification from './notification/reducer'

export default combineReducers({
    user, 
    contacts, 
    attendees,
    dashboard,
    notification
})