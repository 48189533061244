import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Rectangle: React.FC<IconProps> = ({
  fill = '#0099FE',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='8'
      height='9'
      viewBox='0 0 8 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.5' width='8' height='8' rx='2' fill={fill} />
    </svg>
  );
};

export default Rectangle;
