import React from 'react';
import { Image } from '../ui';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Opps: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <Image
      className={className}
      src='/assets/icon/oppsnotfound.png'
      width='80px'
      height='80px'
      alt='oppsnotfound'
    />
  );
};

export default Opps;
