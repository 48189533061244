export const validarCPF = (val: any) => {
  if (!val) {
    val = '';
  }
  var cpf = val.trim();
  cpf = cpf.replace(/\./g, '');
  cpf = cpf.replace('-', '');
  cpf = cpf.split('');
  var v1 = 0;
  var v2 = 0;
  var aux = false;
  for (var i = 1; cpf.length > i; i++) {
    if (cpf[i - 1] != cpf[i]) {
      aux = true;
    }
  }
  if (aux == false) {
    return false;
  }
  for (var i1 = 0, p = 10; cpf.length - 2 > i1; i1++, p--) {
    v1 += cpf[i1] * p;
  }
  v1 = (v1 * 10) % 11;
  if (v1 == 10) {
    v1 = 0;
  }
  if (v1 != cpf[9]) {
    return false;
  }
  for (var i2 = 0, p2 = 11; cpf.length - 1 > i2; i2++, p2--) {
    v2 += cpf[i2] * p2;
  }
  v2 = (v2 * 10) % 11;
  if (v2 == 10) {
    v2 = 0;
  }
  if (v2 != cpf[10]) {
    return false;
  } else {
    return true;
  }
};

export const validarForm = (attendee: any, setError: any) => {
  if (
    !attendee.cpf ||
    !attendee.nome ||
    !attendee.email ||
    !attendee.telefone
  ) {
    setError('Preencha os campos obrigatórios!');

    return false;
  } else if (!validarCPF(attendee.cpf)) {
    setError('Informe um numero de cpf válido!');
    return false;
  } else {
    return true;
  }
};

//when i put this in the function above still invalid: (13) 99730-3537
export const isPhoneInvalid = (phone: string) => {
  const phoneRegex = /^\(?\d{2}\)?[\s-]?(\d{4,5}-?\d{4})$/;
  return !phoneRegex.test(phone);
};

export const isEmailInvalid = (attendee: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !emailRegex.test(attendee);
};
