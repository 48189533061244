import * as actionTypes from './actionTypes'

export const get = () => ({
    type: actionTypes.GET    
})


export const set = (data) => ({
    type: actionTypes.SET,
    payload : { data }
})

export const clear = () => ({
    type: actionTypes.CLEAR,    
}) 

export const loading = (flag) => ({
    type: actionTypes.LOADING,  
    payload: { flag }  
})