import React, { CSSProperties, ReactNode, MouseEvent } from 'react';

interface ContainerProps {
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;
  id?: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const Box: React.FC<ContainerProps> = ({
  children,
  style,
  className,
  id,
  onClick,
}) => {
  return (
    <div style={style} className={className} id={id} onClick={onClick}>
      {children}
    </div>
  );
};

export default Box;
