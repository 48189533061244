import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const AlertWarning: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_362_2554)'>
        <rect
          width='3.2'
          height='8.8'
          transform='translate(6.40002 4.5)'
          fill='#EA4949'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M8.00054 14.8932H4.12157C1.90046 14.8932 0.972333 13.2071 2.04768 11.1472L4.04477 7.32636L5.92663 3.73673C7.06601 1.55439 8.93507 1.55439 10.0744 3.73673L13.9534 11.154C15.0287 13.2139 14.0942 14.9 11.8795 14.9H8.00054V14.8932ZM8.00049 4.96897C8.33186 4.96897 8.60049 5.25429 8.60049 5.60625V9.00554C8.60049 9.3575 8.33186 9.64282 8.00049 9.64282C7.66912 9.64282 7.40049 9.3575 7.40049 9.00554V5.60625C7.40049 5.25429 7.66912 4.96897 8.00049 4.96897ZM7.99697 11.0451C7.55514 11.0451 7.19697 11.4255 7.19697 11.8948C7.19697 12.3641 7.55514 12.7445 7.99697 12.7445H8.00147C8.4433 12.7445 8.80147 12.3641 8.80147 11.8948C8.80147 11.4255 8.4433 11.0451 8.00147 11.0451H7.99697Z'
          fill='#FAFAFA'
        />
      </g>
      <defs>
        <clipPath id='clip0_362_2554'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AlertWarning;
