
import { takeEvery, all, put, delay, call, fork, takeLatest, take, cancel } from 'redux-saga/effects'

import * as userActionTypes from '../reducers/user/actionTypes'
import * as userSaga from './user-saga'

import * as contactsActionTypes from '../reducers/contacts/actionTypes'
import * as contactsSaga from './contacts-saga'

import * as attendeesActionTypes from '../reducers/attendees/actionTypes'
import * as attendeesSaga from './attendees-saga'

import * as dashboardActionTypes from '../reducers/dashboard/actionTypes'
import * as dashboardSaga from './dashboard-saga'

function* allSaga() {

    yield all([
        yield takeLatest(userActionTypes.LOGIN, userSaga.login),
        yield takeLatest(contactsActionTypes.SCAN, contactsSaga.scan),
        yield takeLatest(contactsActionTypes.ADD_SAGA, contactsSaga.addSaga),
        yield takeLatest(contactsActionTypes.GET_VCF, contactsSaga.getVcf),
        yield takeLatest(attendeesActionTypes.SCAN, attendeesSaga.scan),
        yield takeLatest(attendeesActionTypes.SEARCH, attendeesSaga.search),
        yield takeLatest(attendeesActionTypes.CHECKIN, attendeesSaga.checkin),
        yield takeLatest(dashboardActionTypes.GET, dashboardSaga.getData),
    ])
}

export default allSaga;