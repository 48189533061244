import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import Xicon from '../components/icons/Xicon';
import { Typography } from '../components/ui';
import Alert from '../components/ui/alert';
import { useDispatch } from 'react-redux';
import * as notificationActions from '../reducers/notification/actions';

interface AlertContextType {
  showAlert: (
    message: string,
    type: 'success' | 'error' | 'warning' | 'info',
    autoHide?: boolean,
    icon?: ReactNode,
    subMessage?: string
  ) => void;
  hideAlert: () => void;
}

// Create the context
const AlertContext = createContext<AlertContextType | undefined>(undefined);

// AlertProvider component
export const AlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const close = () => dispatch(notificationActions.clear());

  const [alertState, setAlertState] = useState<{
    message: string;
    type: 'success' | 'error' | 'warning' | 'info';
    isVisible: boolean;
    icon?: ReactNode;
    subMessage?: string;
  }>({ message: '', type: 'success', isVisible: false, icon: undefined });

  const hideAlert = () => {
    setAlertState((prevState) => ({ ...prevState, isVisible: false }));
    close();
  };

  const showAlert = (
    message: string,
    type: 'success' | 'error' | 'warning' | 'info',
    autoHide: boolean = true,
    icon: ReactNode,
    subMessage?: string
  ) => {
    setAlertState({ message, type, isVisible: true, icon, subMessage });

    if (autoHide) {
      setTimeout(() => {
        hideAlert();
      }, 5000);
    }
  };

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      <Alert
        message={alertState.message}
        subMessage={alertState.subMessage}
        type={alertState.type}
        icon={alertState.icon}
        onClose={hideAlert}
        isVisible={alertState.isVisible}
      />

      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};
