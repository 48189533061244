import * as actionTypes from './actionTypes'

const initialState = {    
    data : {},     
    loading: false, 
    
}

const reducer = (state = initialState, action ) => {
    switch (action.type){
        case actionTypes.SET: {

            const  { data }  = action.payload

            return {
                ...state, 
                data,
            }
        }

        

        case actionTypes.LOADING: {

            const  { flag }  = action.payload

            return {
                ...state, 
                loading: flag
            }
        }

        case actionTypes.CLEAR: {

            

            return initialState
        }

        default: return state
    }
}

export default reducer