import React from 'react';
import MessageStatus, { MessageStatusProps } from './MessageStatus';
import { Typography } from '../../../components/ui';
import styles from './message.module.css';

type ErrorConfigType = {
  [key: string]: MessageStatusProps;
};

const errorConfig: ErrorConfigType = {
  'Chek-in n\u00e3o realizado! O participante j\u00e1 efetuou o check-in.': {
    status: 'warning',
    errorMessage: 'Já credenciado!',
  },
  'Chek-in não realizado! Participante cancelado.': {
    status: 'error',
    errorMessage: 'Ingresso cancelado!',
  },
  'Chek-in não realizado! Ingresso inválido ou com checkin efetivado.': {
    status: 'error',
    errorMessage:
      'CPF inválido! Por favor, corrija os números e tente novamente.',
  },
  'Checkin realizado com sucesso': {
    status: 'success',
    errorMessage: 'Sucesso na sua solicitação',
  },
};

interface ErrorMessageProps {
  errorMessage: string;
  errorsData: MessageStatusProps;
}

const Message: React.FC<ErrorMessageProps> = ({ errorMessage, errorsData }) => {
  const config = errorConfig[errorMessage];

  const combinedProps = {
    ...errorsData,
    ...config,
  };

  if (config) {
    return <MessageStatus {...combinedProps} />;
  }

  return (
    <Typography tag='h3' className={styles.helpText}>
      Coloque o leitor sobre o QR Code. A leitura será feita automaticamente.
    </Typography>
  );
};

export default Message;
