import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const MenuIcon: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      className={className}
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.19995 2.89995C1.19995 2.23721 1.68348 1.69995 2.27995 1.69995H21.7199C22.3164 1.69995 22.7999 2.23721 22.7999 2.89995C22.7999 3.56269 22.3164 4.09995 21.7199 4.09995H2.27995C1.68348 4.09995 1.19995 3.56269 1.19995 2.89995Z'
        fill='#222222'
      />
      <path
        d='M1.19995 12.5C1.19995 11.8372 1.68348 11.3 2.27995 11.3H21.7199C22.3164 11.3 22.7999 11.8372 22.7999 12.5C22.7999 13.1627 22.3164 13.7 21.7199 13.7H2.27995C1.68348 13.7 1.19995 13.1627 1.19995 12.5Z'
        fill='#222222'
      />
      <path
        d='M2.27995 20.9C1.68348 20.9 1.19995 21.4372 1.19995 22.1C1.19995 22.7627 1.68348 23.2999 2.27995 23.2999H21.7199C22.3164 23.2999 22.7999 22.7627 22.7999 22.1C22.7999 21.4372 22.3164 20.9 21.7199 20.9H2.27995Z'
        fill='#222222'
      />
    </svg>
  );
};

export default MenuIcon;
