import React, { ReactNode, useEffect, useState } from 'react';
import { useAlert } from '../../../../context/AlertContext';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as notificationActions from '../../../../reducers/notification/actions';

const ShowAlert: React.FC<any> = ({ children }) => {
  const { showAlert } = useAlert();

  const dispatch = useDispatch();

  const notification = useSelector((state: any) => state.notification);

  useEffect(() => {
    if (notification.type !== 'none') {
      showAlert(notification.message, notification.type);
    }
  }, [notification.message]);

  return <>{children}</>;
};

export default ShowAlert;
