import { takeEvery, put, takeLatest, call, delay } from 'redux-saga/effects';
import Requester from '../util/requester';

import * as attendeesActions from '../reducers/attendees/actions';
import * as notificationActions from '../reducers/notification/actions';

const _scan = (values) =>
  Requester('/operador/searchattendee/?scan=true', {
    method: 'POST',
    body: values,
  });

const _search = (values) =>
  Requester('/operador/searchattendee/?search=true', {
    method: 'POST',
    body: values,
  });

const _checkin = (values) =>
  Requester('/operador/checkin', {
    method: 'POST',
    body: values,
  });

export function* scan(action) {
  yield put(attendeesActions.loading(true));

  try {
    const { codigo } = action.payload;
    const result = yield call(_scan, { codigo });
    if (!result.disconnected) {
      if (result.status === 'success') {
        const { data } = result;
        if (data?.[0]?.qrCode) {
          yield put(
            notificationActions.success(
              'Checkin realizado com sucesso',
              result.data[0], {
                autoClose: 20000,
              }
            )
          );
        }
        yield put(attendeesActions.results(data));
      } else {
        if (result.message === '-Offline-') {
          yield put(
            notificationActions.success(
              'Leitura realizada com sucesso! [Modo Offline]'
            )
          );
        } else {
          if (
            result.message ===
            'CPF inválido! Por favor, corrija os números e tente novamente.'
          ) {
            yield put(
              notificationActions.error(result.message, result.errors, {
                autoClose: 20000,
              })
            );
          } else {
            yield put(
              notificationActions.error(result.message, result.errors, {
                autoClose: 20000,
              })
            );
          }
        }
      }
    }
  } catch (error) {
    yield put(
      notificationActions.error(
        'Falha de comunicação. Verifique sua conexão com a internet'
      )
    );
  } finally {
    yield put(attendeesActions.loading(false));
  }
}
export function* search(action) {
  yield put(attendeesActions.loading(true));

  try {
    const { codigo, clearList } = action.payload;

    if (clearList) {
      yield put(attendeesActions.results([]));
      return;
    }

    const result = yield call(_search, { codigo });

    if (!result.disconnected) {
      if (result.status === 'success') {
        const { data } = result;
        yield put(attendeesActions.results(data));
      } else {
        if (result.message == '-Offline-') {
          yield put(
            notificationActions.success(
              'Leitura realizada com sucesso! [Modo Offline]'
            )
          );
        } else {
          yield put(
            notificationActions.error(result.message, { autoClose: 20000 })
          );
        }
      }
    }
  } catch (error) {
    yield put(
      notificationActions.error(
        'Falha de comunicação. Verifique sua conexão com a internet'
      )
    );
  } finally {
    yield put(attendeesActions.loading(false));
  }
}

export function* checkin(action) {
  yield put(attendeesActions.loading(true));

  try {
    const result = yield call(_checkin, action.payload);

    const { data } = result;

    if (!result.disconnected) {
      if (result.status === 'success') {
        yield put(attendeesActions.clear());
        yield put(notificationActions.success(data.message));
      } else {
        yield put(notificationActions.error(result.message));
      }
    }
  } catch (error) {
    yield put(
      notificationActions.error(
        'Falha de comunicação. Verifique sua conexão com a internet'
      )
    );
  } finally {
    yield put(attendeesActions.loading(false));
  }
}
