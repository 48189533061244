import React, { ReactNode, useState } from 'react';
import Typography from '../typography';
import Xicon from '../../icons/Xicon';
import Box from '../box';
import styles from './alert.module.css';
// Define the props interface
interface AlertProps {
  message: string;
  subMessage?: string;
  type: 'success' | 'error' | 'warning' | 'info';
  icon?: ReactNode;
  isVisible?: boolean;
  onClose: () => void;
}

// Alert component that is used within the provider
const Alert: React.FC<AlertProps> = ({
  message,
  type,
  onClose,
  icon,
  subMessage,
  isVisible,
}) => {
  return (
    <Box
      className={`${styles.alertMainBox} ${
        isVisible ? styles.alertMainOpen : ''
      }`}
    >
      <Box
        className={`${styles.alertBox} ${isVisible ? styles.alertOpen : ''}`}
        style={{
          backgroundColor:
            type === 'success'
              ? '#53A462'
              : type === 'error'
              ? '#EA4949'
              : type === 'warning'
              ? '#F5AA2A'
              : 'blue',
        }}
      >
        {icon}
        <Typography className={styles.alertMessage}>{message}</Typography>
        <Xicon className={styles.xIcon} fill='#ffffff' onClick={onClose} />
      </Box>
      {subMessage && (
        <Box style={{ padding: '16px 20px', backgroundColor: '#FAF6DC' }}>
          <Typography className={styles.subMessage}>{subMessage}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Alert;
