import * as actionTypes from './actionTypes';

const initialState = {
  message: '',
  type: 'none',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD:
    case actionTypes.SUCCESS:
    case actionTypes.INFO:
    case actionTypes.ERROR: {
      const { message, errors, type } = action.payload;

      return {
        ...state,
        message,
        errors,
        type,
      };
    }

    case actionTypes.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reducer;
