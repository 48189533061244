import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as attendeesActions from '../../../reducers/attendees/actions';
import { IonButton, IonSearchbar, IonSpinner } from '@ionic/react';

import styles from './searchBar.module.css';
import { Box, Button, Typography } from '../../../components/ui';
import Searchbar from '../../../components/ui/searchbar';
import { useSelector } from 'react-redux';

interface SearchBarProps {
  search: string;
  setSearch: (value: string) => void;
}

const SearchBar = () => {
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const { results } = useSelector((state: any) => ({
    results: state.attendees.results,
  }));

  const searchWithEnter = (key: any) => {
    if (key === 'Enter') {
      dispatch(attendeesActions.search(search.toUpperCase()));
    }
  };

  const cleanSearch = () => {
    setSearch('');
    dispatch(attendeesActions.search(''));
  };

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    dispatch(attendeesActions.search(search.toUpperCase()));
  };

  return (
    <>
      <Typography tag='h1' className={styles.title}>
        Busque o participante
      </Typography>
      <Searchbar
        placeholder='Busque pelo código, nome, cpf e e-mail'
        onKeyPress={(e) => searchWithEnter(e.key)}
        onChange={handleSearchChange}
        onCancel={cleanSearch}
        value={search}
      />
      <Button
        className={styles.searchButton}
        disabled={search.trim() === ''}
        onClick={handleSearchClick}
        label='Pesquisar'
      />
      {/*{results.length < 0 && (
       
      )}*/}
    </>
  );
};

export default SearchBar;
