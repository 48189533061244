import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const QrCode: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.54856 7.17066C3.54856 5.16555 5.16549 3.54862 7.1706 3.54862H9.66856C10.1514 3.54862 10.5428 3.15719 10.5428 2.67433C10.5428 2.19148 10.1514 1.80005 9.66856 1.80005H7.1706C4.19978 1.80005 1.79999 4.19984 1.79999 7.17066V9.66862C1.79999 10.1515 2.19142 10.5429 2.67427 10.5429C3.15713 10.5429 3.54856 10.1515 3.54856 9.66862V7.17066Z'
        fill='#00A7F0'
      />
      <path
        d='M14.3314 1.80005C13.8486 1.80005 13.4571 2.19148 13.4571 2.67433C13.4571 3.15719 13.8486 3.54862 14.3314 3.54862H16.8294C18.8345 3.54862 20.4514 5.16555 20.4514 7.17066V9.66862C20.4514 10.1515 20.8428 10.5429 21.3257 10.5429C21.8086 10.5429 22.2 10.1515 22.2 9.66862V7.17066C22.2 4.19984 19.8002 1.80005 16.8294 1.80005H14.3314Z'
        fill='#00A7F0'
      />
      <path
        d='M21.3257 14.6229C21.8086 14.6229 22.2 15.0143 22.2 15.4972V16.9543C22.2 19.856 19.856 22.2001 16.9543 22.2001H15.4971C15.0143 22.2001 14.6228 21.8086 14.6228 21.3258C14.6228 20.8429 15.0143 20.4515 15.4971 20.4515H16.9543C18.8903 20.4515 20.4514 18.8903 20.4514 16.9543V15.4972C20.4514 15.0143 20.8428 14.6229 21.3257 14.6229Z'
        fill='#00A7F0'
      />
      <path
        d='M3.54856 14.3315C3.54856 13.8486 3.15713 13.4572 2.67427 13.4572C2.19142 13.4572 1.79999 13.8486 1.79999 14.3315V16.8294C1.79999 19.8003 4.19978 22.2001 7.1706 22.2001H9.66856C10.1514 22.2001 10.5428 21.8086 10.5428 21.3258C10.5428 20.8429 10.1514 20.4515 9.66856 20.4515H7.1706C5.16549 20.4515 3.54856 18.8346 3.54856 16.8294V14.3315Z'
        fill='#00A7F0'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.59435 5.40584C6.04928 4.9509 6.66065 4.80005 7.2617 4.80005H9.12685C9.7279 4.80005 10.3393 4.9509 10.7942 5.40584C11.2491 5.86077 11.4 6.47214 11.4 7.07319V8.93833C11.4 9.53939 11.2491 10.1508 10.7942 10.6057C10.3393 11.0606 9.7279 11.2115 9.12685 11.2115H7.2617C6.66065 11.2115 6.04928 11.0606 5.59435 10.6057C5.13941 10.1508 4.98856 9.53939 4.98856 8.93833V7.07319C4.98856 6.47214 5.13941 5.86077 5.59435 5.40584ZM6.83077 6.64226C6.81942 6.65362 6.73713 6.74167 6.73713 7.07319V8.93833C6.73713 9.26985 6.81942 9.35791 6.83077 9.36926C6.84213 9.38062 6.93018 9.46291 7.2617 9.46291H9.12685C9.45836 9.46291 9.54642 9.38062 9.55778 9.36926C9.56913 9.35791 9.65142 9.26985 9.65142 8.93833V7.07319C9.65142 6.74167 9.56913 6.65362 9.55778 6.64226C9.54642 6.63091 9.45836 6.54862 9.12685 6.54862H7.2617C6.93018 6.54862 6.84213 6.63091 6.83077 6.64226Z'
        fill='#00A7F0'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.0617 4.80005C14.4607 4.80005 13.8493 4.9509 13.3943 5.40584C12.9394 5.86077 12.7886 6.47214 12.7886 7.07319V8.93833C12.7886 9.53939 12.9394 10.1508 13.3943 10.6057C13.8493 11.0606 14.4607 11.2115 15.0617 11.2115H16.9268C17.5279 11.2115 18.1393 11.0606 18.5942 10.6057C19.0491 10.1508 19.2 9.53939 19.2 8.93833V7.07319C19.2 6.47214 19.0491 5.86077 18.5942 5.40584C18.1393 4.9509 17.5279 4.80005 16.9268 4.80005H15.0617ZM14.5371 7.07319C14.5371 6.74167 14.6194 6.65362 14.6308 6.64226C14.6421 6.63091 14.7302 6.54862 15.0617 6.54862H16.9268C17.2584 6.54862 17.3464 6.63091 17.3578 6.64226C17.3691 6.65362 17.4514 6.74167 17.4514 7.07319V8.93833C17.4514 9.26985 17.3691 9.35791 17.3578 9.36926C17.3464 9.38062 17.2584 9.46291 16.9268 9.46291H15.0617C14.7302 9.46291 14.6421 9.38062 14.6308 9.36926C14.6194 9.35791 14.5371 9.26985 14.5371 8.93833V7.07319Z'
        fill='#00A7F0'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.59435 13.3944C6.04928 12.9395 6.66065 12.7886 7.2617 12.7886H9.12685C9.7279 12.7886 10.3393 12.9395 10.7942 13.3944C11.2491 13.8493 11.4 14.4607 11.4 15.0618V16.9269C11.4 17.528 11.2491 18.1393 10.7942 18.5943C10.3393 19.0492 9.7279 19.2001 9.12685 19.2001H7.2617C6.66065 19.2001 6.04928 19.0492 5.59435 18.5943C5.13941 18.1393 4.98856 17.528 4.98856 16.9269V15.0618C4.98856 14.4607 5.13941 13.8493 5.59435 13.3944ZM6.83077 14.6308C6.81942 14.6422 6.73713 14.7302 6.73713 15.0618V16.9269C6.73713 17.2584 6.81942 17.3465 6.83077 17.3578C6.84213 17.3692 6.93018 17.4515 7.2617 17.4515H9.12685C9.45836 17.4515 9.54642 17.3692 9.55778 17.3578C9.56913 17.3465 9.65142 17.2584 9.65142 16.9269V15.0618C9.65142 14.7302 9.56913 14.6422 9.55778 14.6308C9.54642 14.6195 9.45836 14.5372 9.12685 14.5372H7.2617C6.93018 14.5372 6.84213 14.6195 6.83077 14.6308Z'
        fill='#00A7F0'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.0617 12.7886C14.4607 12.7886 13.8493 12.9395 13.3943 13.3944C12.9394 13.8493 12.7886 14.4607 12.7886 15.0618V16.9269C12.7886 17.528 12.9394 18.1393 13.3943 18.5943C13.8493 19.0492 14.4607 19.2001 15.0617 19.2001H16.9268C17.5279 19.2001 18.1393 19.0492 18.5942 18.5943C19.0491 18.1393 19.2 17.528 19.2 16.9269V15.0618C19.2 14.4607 19.0491 13.8493 18.5942 13.3944C18.1393 12.9395 17.5279 12.7886 16.9268 12.7886H15.0617ZM14.5371 15.0618C14.5371 14.7302 14.6194 14.6422 14.6308 14.6308C14.6421 14.6195 14.7302 14.5372 15.0617 14.5372H16.9268C17.2584 14.5372 17.3464 14.6195 17.3578 14.6308C17.3691 14.6422 17.4514 14.7302 17.4514 15.0618V16.9269C17.4514 17.2584 17.3691 17.3465 17.3578 17.3578C17.3464 17.3692 17.2584 17.4515 16.9268 17.4515H15.0617C14.7302 17.4515 14.6421 17.3692 14.6308 17.3578C14.6194 17.3465 14.5371 17.2584 14.5371 16.9269V15.0618Z'
        fill='#00A7F0'
      />
    </svg>
  );
};

export default QrCode;
