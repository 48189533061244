export const cpf_mask = (v: any) => {
  if (!v) {
    v = '';
  }
  v = v.replace(/\D/g, '');
  v = v.replace(/(\d{3})(\d)/, '$1.$2');
  v = v.replace(/(\d{3})(\d)/, '$1.$2');
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  return v;
};

export const fone_mask = (v: any) => {
  v = v.replace(/\D/g, '');
  v = v.replace(/(\d{0})(\d)/, '$1($2');
  v = v.replace(/(\d{2})(\d)/, '$1) $2');
  v = v.replace(/(\d{5})(\d)/, '$1-$2');
  return v;
};
