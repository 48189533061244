import React, { CSSProperties, ChangeEvent, FC, FocusEvent } from 'react';
import Box from '../box';
import Typography from '../typography';
import styles from './input.module.css';

interface InputProps {
  type?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  mask?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  style?: CSSProperties;
  maxLength?: number;
  warning?: string;
  disabled?: boolean;
}

const formatCPF = (value?: string) => {
  const cpf: any = value?.replace(/\D/g, ''); // Remove non-digits
  const formatted =
    cpf.substring(0, 3) +
    (cpf.length > 3 ? '.' : '') +
    cpf.substring(3, 6) +
    (cpf.length > 6 ? '.' : '') +
    cpf.substring(6, 9) +
    (cpf.length > 9 ? '-' : '') +
    cpf.substring(9, 11);
  return formatted;
};

const Input: FC<InputProps> = ({
  type,
  name,
  placeholder,
  value,
  onChange,
  onFocus,
  onBlur,
  label,
  className,
  style,
  mask,
  maxLength,
  warning,
  disabled,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let formattedValue = e.target.value;
    if (mask === 'cpf') {
      formattedValue = formatCPF(formattedValue);
    }
    if (onChange) {
      onChange({ ...e, target: { ...e.target, value: formattedValue } });
    }
  };

  return (
    <Box style={{ textAlign: 'start' }}>
      {label && <Typography className={styles.label}>{label}</Typography>}
      <input
        style={style}
        type={type}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        maxLength={mask === 'cpf' ? 14 : maxLength}
        value={mask === 'cpf' ? formatCPF(value) : value}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={`${className} ${styles.input} ${
          warning && styles.inputWarning
        }  `}
      />
      {warning && <Typography className={styles.warning}>{warning}</Typography>}
    </Box>
  );
};

export default Input;
