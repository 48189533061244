import React from 'react';
import { Box, Image, Typography } from '../../../components/ui';
import styles from './message.module.css';
import Container from '../../../components/ui/container';

export interface MessageStatusProps {
  nome?: string;
  documento?: string;
  nome_ingresso?: string;
  status?: 'success' | 'error' | 'warning';
  cpf?: string;
  codigo?: string;
  errorMessage?: string;
  data_credenciamento?: string;
}

const MessageStatus: React.FC<MessageStatusProps> = ({
  nome,
  nome_ingresso,
  cpf,
  codigo,
  status,
  errorMessage,
  data_credenciamento,
}) => {
  return (
    <Container className={styles.container}>
      {/* header do card de status */}
      <Box className={styles.header}>
        <Box style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
          <img
            src={
              status === 'success'
                ? '/assets/icon/success.svg'
                : status === 'warning'
                ? '/assets/icon/warning.svg'
                : '/assets/icon/error.svg'
            }
            width='24px'
            height='24px'
            alt='errororsuccess'
          />
          <Typography
            tag='h1'
            style={{
              color:
                status === 'success'
                  ? '#3CCD6E'
                  : status === 'warning'
                  ? '#F5910D'
                  : '#FF5858',
            }}
          >
            {errorMessage}
          </Typography>
        </Box>
        {data_credenciamento && (
          <Typography tag='h2'>{data_credenciamento}</Typography>
        )}
      </Box>
      {/* body do card de status */}
      <Box className={styles.body}>
        <Typography tag='p' className={styles.paragraph}>
          {nome}
        </Typography>
        <Typography tag='p' className={styles.smallParagraph}>
          {nome_ingresso}
        </Typography>
        <Typography tag='p' className={styles.documentParagraph}>
          Documento: {cpf}
        </Typography>
        <Typography tag='p' className={styles.ticketNumberParagraph}>
          Nº do ingresso: {codigo}
        </Typography>
      </Box>
    </Container>
  );
};

export default MessageStatus;
