import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '../../../../components/ui';
import styles from './mainCard.module.css';
import { Doughnut } from 'react-chartjs-2';

import PageLayout from '../../../../components/layout/Page';
import * as dashboardActions from './../../../../reducers/dashboard/actions';
import Refresh from '../../../../components/icons/Refresh';
import { useSelector } from 'react-redux';
import Container from '../../../../components/ui/container';
import Skeleton from '../../../../components/ui/skeleton';
import Rectangle from '../../../../components/icons/Rectangle';

const MainCard = () => {
  const { dashData } = useSelector((state: any) => ({
    dashData: state?.dashboard?.data,
    loading: state.dashboard.loading,
    evento_nome: state.user?.evento?.nome || '',
  }));

  const chartDataFormatter = (data: any) => {
    return {
      //labels: ['Ausentes', 'Presentes'],
      datasets: [
        {
          label: 'Participação',
          data: [data?.total - data?.checked, data?.checked],
          backgroundColor: ['#CCC', '#09F'],
          borderColor: ['#bbb', '#09C'],
          borderWidth: 1,
        },
      ],
    };
  };

  const infos = [
    { label: 'Presentes', color: '#0099FE' },
    { label: 'Ausentes', color: 'rgba(34, 34, 34, 0.24)' },
  ];

  return (
    <Box className={styles.card}>
      <Typography className={styles.cardTitle}>
        Total de Check-In realizados
      </Typography>
      <Box className={styles.cardContent}>
        <Box className={styles.doughnutBox}>
          {dashData && (
            <Doughnut
              data={chartDataFormatter(dashData?.general)}
              options={{ maintainAspectRatio: false }}
            />
          )}
        </Box>
        <Box className={styles.doughnutBoxData}>
          <Box className={styles.doughnutBoxDataFirstLine}>
            <Typography className={styles.doughnutBoxDataFirstLineValue}>
              {dashData?.general?.checked}/
              <Typography className={styles.doughnutBoxDataFirstLineTotal}>
                {dashData?.general?.total}
              </Typography>
            </Typography>

            <Typography className={styles.doughnutBoxDataFirstLinePercentage}>
              {(
                (dashData?.general?.checked / dashData?.general?.total) *
                100
              ).toFixed(2)}
              %
            </Typography>
          </Box>
          {infos.map((info, index) => (
            <Box key={index} className={styles.doughnutBoxInfo}>
              <Rectangle fill={info.color} />
              <Typography className={styles.doughnutBoxInfoText}>
                {info.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MainCard;
