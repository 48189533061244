import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '../../../components/ui';
import styles from './charts.module.css';

import * as dashboardActions from './../../../reducers/dashboard/actions';
import { useSelector } from 'react-redux';
import Skeleton from '../../../components/ui/skeleton';
import MainCard from './mainCard';
import MiniCard from './miniCard';

const Charts = () => {
  const dispatch = useDispatch();

  const { dashData, loading, evento_nome } = useSelector((state: any) => ({
    dashData: state.dashboard.data,
    loading: state.dashboard.loading,
    evento_nome: state.user?.evento?.nome || '',
  }));

  const [detail, setDetail] = useState(null);

  const getData: any = () => {
    setDetail(null);
    dispatch(dashboardActions.get());
  };
  const clear = () => dispatch(dashboardActions.clear());

  const chartDataFormatter = (data: any) => {
    return {
      //labels: ['Ausentes', 'Presentes'],
      datasets: [
        {
          label: 'Participação',
          data: [data?.total - data?.checked, data?.checked],
          backgroundColor: ['#CCC', '#09F'],
          borderColor: ['#bbb', '#09C'],
          borderWidth: 1,
        },
      ],
    };
  };

  useEffect(() => {
    getData();

    // Cleanup function
    return () => {
      clear();
    };
  }, []);

  return (
    <Box
      style={{
        display: 'grid',
        gridGap: '10px',
        maxWidth: '600px',
        margin: 'auto',
        padding: '8px 20px',
      }}
    >
      {/* Row 1 with 1 column */}
      <Box style={{ gridTemplateColumns: '1fr' }}>
        {loading ? <Skeleton height='181px' /> : <MainCard />}
      </Box>

      <Box className={styles.gridMiniCards}>
        {loading ? (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton
                key={index}
                height='148px'
                width='100%'
                borderRadius='4px'
              />
            ))}
          </>
        ) : (
          <>
            {(dashData?.tickets || []).map((data: any, idx: any) => (
              <MiniCard
                title={data.nome}
                values={
                  <>
                    {data.checked}/
                    {data.bilhete_qt > 0 ? data.bilhete_qt : data.geral}
                  </>
                }
                data={data}
                dataFormated={chartDataFormatter(data)}
              />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Charts;
