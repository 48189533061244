import { takeEvery, put, takeLatest, call, delay } from 'redux-saga/effects'
import Requester  from '../util/requester'

import * as dashboardActions from '../reducers/dashboard/actions'
import * as notificationActions from '../reducers/notification/actions'



const _getData = () => Requester('/operador/dashboard', {
    method: 'POST',
})


export function* getData(action) {

    yield put(dashboardActions.loading(true))

    

    try {
        
        const result = yield call(_getData)

        if(!result.disconnected){
            if(result.status){
            
                const { data }  = result            
    
                yield put(dashboardActions.set(data))
            }
            else{
                yield put(notificationActions.error('Não foi possível carregar os dados'))
            }
        }
        
    } catch(error){
        yield put(notificationActions.error('Falha de comunicação. Verifique sua conexão com a internet'))
    }
    finally{
        yield put(dashboardActions.loading(false))
    }
}


