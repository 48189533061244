import { takeEvery, put, takeLatest, call } from 'redux-saga/effects';
import Requester from '../util/requester';

import * as userActions from '../reducers/user/actions';
import * as notificationActions from '../reducers/notification/actions';

const _login = (values) =>
  Requester('/auth/operador', {
    method: 'POST',
    body: values,
  });

export function* login(action) {
  yield put(userActions.setLoading(true));

  try {
    const { code, pass } = action.payload;
    const result = yield call(_login, { code, pass });

    if (result.status === 'success') {
      const { data } = result;

      delete data['pass'];

      yield put(
        userActions.set({
          ...data,
          isLogged: true,
        })
      );

      yield put(notificationActions.success(`Bem-vindo (a), ${data.nome}`));
    } else {
      yield put(notificationActions.error('Falha de autenticação.'));
    }
  } catch (error) {
    yield put(
      notificationActions.error(
        'Falha de comunicação. Verifique se você está conectado a internet.'
      )
    );
  } finally {
    yield put(userActions.setLoading(false));
  }
}
