import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import * as attendeesActions from '../../reducers/attendees/actions';

import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonIcon,
  IonBadge,
  IonCard,
  IonButton,
  IonSpinner,
  IonInput,
  useIonAlert,
} from '@ionic/react';
import {
  checkmark,
  lockClosed,
  alertCircle,
  qrCode,
  pricetagOutline,
} from 'ionicons/icons';
import { el } from 'date-fns/locale';

const AttendeeDetail = () => {
  const dispatch = useDispatch();

  const { attendee: attendeeCheckin, loading } = useSelector(
    (state) => state.attendees
  );
  const [presentAlert] = useIonAlert();

  const checkin = (attendee) => {
    if (validarForm(attendee)) {
      dispatch(attendeesActions.checkin(attendee));
    }
  };

  const validarForm = (attendee) => {
    if (
      !attendee.cpf ||
      !attendee.nome ||
      !attendee.email ||
      !attendee.telefone
    ) {
      setError('Preencha os campos obrigatórios!');

      return false;
    } else if (!validarCPF(attendee.cpf)) {
      setError('Informe um numero de cpf válido!');
      return false;
    } else {
      return true;
    }
  };

  const [error, setError] = useState('');

  const validarCPF = (val) => {
    return true;
    if (!val) {
      val = '';
    }
    var cpf = val.trim();
    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');
    var v1 = 0;
    var v2 = 0;
    var aux = false;
    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }
    if (aux == false) {
      return false;
    }
    for (var i1 = 0, p = 10; cpf.length - 2 > i1; i1++, p--) {
      v1 += cpf[i1] * p;
    }
    v1 = (v1 * 10) % 11;
    if (v1 == 10) {
      v1 = 0;
    }
    if (v1 != cpf[9]) {
      return false;
    }
    for (var i2 = 0, p2 = 11; cpf.length - 1 > i2; i2++, p2--) {
      v2 += cpf[i2] * p2;
    }
    v2 = (v2 * 10) % 11;
    if (v2 == 10) {
      v2 = 0;
    }
    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  };

  const cpf_mask = (v) => {
    if (!v) {
      v = '';
    }
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return v;
  };

  const fone_mask = (v) => {
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{0})(\d)/, '$1($2');
    v = v.replace(/(\d{2})(\d)/, '$1) $2');
    v = v.replace(/(\d{5})(\d)/, '$1-$2');
    return v;
  };

  const isFoneInvalido = (attendee) => {
    return attendee.telefone && attendee.telefone.length < 9;
  };

  const isEmailInvalido = (attendee) => {
    return attendee.email && !attendee.email.includes('@');
  };

  const [attendee, setAttendee] = useState({
    ...attendeeCheckin,
    cpf: validarCPF(attendeeCheckin.cpf) ? attendeeCheckin.cpf : '',
    email: !isEmailInvalido(attendeeCheckin) ? attendeeCheckin.email : '',
    telefone: !isFoneInvalido(attendeeCheckin) ? attendeeCheckin.telefone : '',
  });

  return (
    <>
      <IonList>
        <IonListHeader lines='inset'>
          <IonLabel>
            <p style={{ textAlign: 'center' }}>Dados do participante</p>
          </IonLabel>
        </IonListHeader>

        {attendee.data_credenciamento ? (
          <IonItem lines='none'>
            <IonIcon icon={lockClosed} slot='start'></IonIcon>
            <IonLabel textCenter>
              <p>
                <b>{attendee.codigo}</b>
              </p>
            </IonLabel>
            <IonBadge slot='end' color='medium'>
              Registrado
            </IonBadge>
          </IonItem>
        ) : (
          <IonItem lines='none'>
            <IonIcon icon={qrCode} slot='start'></IonIcon>
            <IonLabel textCenter>
              <p>
                <b>{attendee.codigo}</b>
              </p>
            </IonLabel>
            {attendee.cancelado ? (
              <IonBadge slot='end' color='danger'>
                Cancelado
              </IonBadge>
            ) : (
              <IonBadge slot='end' color='success'>
                Válido
              </IonBadge>
            )}
          </IonItem>
        )}
        <IonItem lines='none'>
          <IonIcon icon={pricetagOutline} slot='start'></IonIcon>
          <IonLabel textCenter>
            <p>
              <b>{attendee.nome_bilhete}</b>
            </p>
          </IonLabel>
        </IonItem>

        <IonItem>
          <IonLabel position='floating'>CPF*:</IonLabel>
          <IonInput
            placeholder='999.999.999-00'
            value={cpf_mask(attendee.cpf)}
            pattern='.{10,11}[0-9]+$'
            maxlength='14'
            onIonChange={(e) => {
              setAttendee({ ...attendee, cpf: e.detail.value });
            }}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position='floating'>Nome*:</IonLabel>
          <IonInput
            placeholder='Nome'
            value={attendee.nome}
            onIonChange={(e) => {
              setAttendee({ ...attendee, nome: e.detail.value });
            }}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position='floating'>Email*:</IonLabel>
          <IonInput
            type='email'
            placeholder='exemplo@exemplo.com'
            value={attendee.email}
            onIonChange={(e) => {
              setAttendee({ ...attendee, email: e.detail.value });
            }}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position='floating'>Telefone*:</IonLabel>
          <IonInput
            pattern='.{10,11}[0-9]+$'
            maxlength='15'
            placeholder='(99) 9999-9999'
            value={fone_mask(attendee.telefone)}
            onIonChange={(e) => {
              setAttendee({ ...attendee, telefone: e.detail.value });
            }}
          ></IonInput>
        </IonItem>
      </IonList>

      {attendee.data_credenciamento ? (
        <IonCard>
          <IonItem lines='none' color='dark'>
            <IonIcon icon={alertCircle} slot='start'></IonIcon>
            <IonLabel className='ion-text-wrap'>
              <p>
                Já foi realizado checkin para este ingresso na data:{' '}
                <b>
                  {moment(attendee.data_credenciamento).format(
                    'DD/MM/YYYY HH:mm'
                  )}
                </b>
                . Em caso de problema peça para o participante obter orientações
                com a organização do evento.
              </p>
            </IonLabel>
          </IonItem>
        </IonCard>
      ) : loading ? (
        <IonButton
          size='large'
          expand='block'
          disabled={true}
          style={{ margin: '20px' }}
        >
          <IonSpinner></IonSpinner>
        </IonButton>
      ) : (
        <>
          <p
            className='ion-text-center'
            style={{ padding: '10px', color: '#ff0000 ' }}
          >
            {error}
          </p>
          <p className='ion-text-center' style={{ padding: '10px' }}>
            Ao confirmar o checkin esse ingresso será invalidado para a
            realização de um novo checkin. Deseja realmente prosseguir?
          </p>
          <IonButton
            size='large'
            expand='block'
            style={{ margin: '20px' }}
            onClick={() => checkin(attendee)}
          >
            <IonIcon icon={checkmark} slot='start'></IonIcon>
            Confirmar checkin
          </IonButton>
        </>
      )}
    </>
  );
};

export default AttendeeDetail;
