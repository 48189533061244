import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const CorrectCircle: React.FC<IconProps> = ({
  fill = '#fafafa',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      className={className}
      style={style}
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.2273 7.05127C11.4597 6.81931 11.46 6.4429 11.2281 6.21053C10.9961 5.97816 10.6197 5.97783 10.3873 6.20978L7.06239 9.52885L5.61304 8.07949C5.38087 7.84732 5.00446 7.84732 4.77229 8.07949C4.54013 8.31165 4.54013 8.68807 4.77229 8.92023L6.64165 10.7896C6.87367 11.0216 7.2498 11.0218 7.48202 10.79L11.2273 7.05127Z'
        fill='#FFF'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.99999 1.3C4.03863 1.3 0.799988 4.53864 0.799988 8.5C0.799988 12.4614 4.03863 15.7 7.99999 15.7C11.9613 15.7 15.2 12.4614 15.2 8.5C15.2 4.53864 11.9613 1.3 7.99999 1.3ZM1.98898 8.5C1.98898 5.19531 4.69529 2.48899 7.99999 2.48899C11.3047 2.48899 14.011 5.19531 14.011 8.5C14.011 11.8047 11.3047 14.511 7.99999 14.511C4.69529 14.511 1.98898 11.8047 1.98898 8.5Z'
        fill='#FFF'
      />
    </svg>
  );
};

export default CorrectCircle;
