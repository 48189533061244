import React, { useEffect, useRef, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Box, Image, Typography } from '../../../components/ui';
import styles from './scanner.module.css';
import useResponsiveStyles from '../../../hooks/useResponsiveStyles';
import Skeleton from '../../../components/ui/skeleton';

interface QrCodeScannerProps {
  handleResult: (result: any, error: any) => void;
}

const QrCodeScanner: React.FC<QrCodeScannerProps> = ({ handleResult }) => {
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const width = useResponsiveStyles(['100%', '100%', '100%', '100%', '100%']);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return (
    <Box className={styles.scannerBox}>
      {isLoading && (
        <Skeleton
          height='340px'
          style={{ position: 'absolute', top: 0, left: 0, borderRadius: '0px' }}
        />
      )}
      <Image
        src={'/assets/icon/qrcode-target.svg'}
        className={styles.scannerTarget}
        alt='qrcode-target'
      />

      <QrReader
        children={<Skeleton height='260px' />}
        onResult={handleResult}
        scanDelay={isSafari ? 100 : 1}
        constraints={{ facingMode: 'environment' }}
        className={styles.qrReader}
        videoContainerStyle={{ display: 'flex', justifyContent: 'center' }}
        containerStyle={{
          minHeight: '260px',
          maxHeight: '340px',
          margin: 'auto',
          width: width,
        }}
        videoStyle={{
          aspectRatio: '1',
          objectFit: 'cover',
          minHeight: '260px',
          maxHeight: '340px',
          width: '100%',
        }}
      />
    </Box>
  );
};

export default QrCodeScanner;
