import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import Box from '../box';
import Input from '../input';
import SearchIcon from '../../icons/SearchIcon';
import styles from './searchbar.module.css';
import { useFocus } from '../../../hooks';
import Xicon from '../../icons/Xicon';
interface SearchbarProps {
  type?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onCancel?: () => void;
  label?: string;
  className?: string;
}
const Searchbar: FC<SearchbarProps> = ({
  type = 'text',
  name,
  placeholder,
  value = '',
  label,
  className,
  onChange,
  onCancel,
  onKeyPress,
}) => {
  const [isFocused, bind] = useFocus();

  return (
    <Box style={{ position: 'relative' }}>
      <SearchIcon
        color={isFocused ? '#00A7F0' : '#222'}
        style={{
          position: 'absolute',
          left: '15px',
          top: '11px',
        }}
      />
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        className={`${className || ''} ${styles.searchbar}`}
        style={{
          border: `1px solid ${
            isFocused ? 'color/icon/brand/default' : 'rgba(34, 34, 34, 0.08)'
          } `,
        }}
        {...bind}
      />
      {value.length > 0 && (
        <Xicon
          onClick={onCancel}
          style={{
            position: 'absolute',
            right: '15px',
            top: '12px',
            cursor: 'pointer',
          }}
        />
      )}
    </Box>
  );
};

export default Searchbar;
