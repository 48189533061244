// Layout.tsx
import React, { ReactNode, useState } from 'react';

import {
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Navbar, { NavbarProps } from './navbar/index';
import NavButton, { NavButtonProps } from './NavButton';
import { AlertProvider, useAlert } from '../../context/AlertContext';
import ShowAlert from './navbar/alerts';
import Container from '../ui/container';

interface LayoutProps extends NavbarProps {
  children: ReactNode;
  pageId: string;
  navButtonProps?: NavButtonProps;
  showNavbar?: boolean;
  showNavbutton?: boolean;
}

const PageLayout: React.FC<LayoutProps> = ({
  children,
  pageId,
  navButtonProps,
  showNavbar = true,
  showNavbutton = true,
  customIcon,
}) => {
  const { onNavButtonClick, icon, color } = navButtonProps || {};

  return (
    <>
      <IonPage id={pageId} style={{ maxWidth: '500px', margin: 'auto' }}>
        {showNavbar && <Navbar customIcon={customIcon} />}
        <IonContent fullscreen={true}>
          <AlertProvider>
            <ShowAlert>
              {children}
              {showNavbutton && (
                <NavButton
                  pageId={pageId}
                  onNavButtonClick={onNavButtonClick}
                  icon={icon}
                  color={color}
                />
              )}
            </ShowAlert>
          </AlertProvider>
        </IonContent>
      </IonPage>
    </>
  );
};

export default PageLayout;
