import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Refresh: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.19995 12C1.19995 6.03527 6.03527 1.19995 12 1.19995C17.9646 1.19995 22.7999 6.03527 22.7999 12C22.7999 17.9646 17.9646 22.7999 12 22.7999C6.03527 22.7999 1.19995 17.9646 1.19995 12ZM12 2.98344C7.02027 2.98344 2.98344 7.02027 2.98344 12C2.98344 16.9796 7.02027 21.0165 12 21.0165C16.9796 21.0165 21.0165 16.9796 21.0165 12C21.0165 7.02027 16.9796 2.98344 12 2.98344Z'
        fill='#818181'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M16.7446 11.7812C17.232 11.8516 17.5702 12.3037 17.4998 12.7912C17.3362 13.925 16.8313 15.0485 15.9399 15.94L15.9382 15.9417C13.7582 18.1098 10.2323 18.1123 8.06004 15.94C7.76236 15.6423 7.4989 15.3075 7.2819 14.9458C7.02852 14.5235 7.16546 13.9757 7.58777 13.7224C8.01008 13.469 8.55784 13.6059 8.81123 14.0282C8.95093 14.2611 9.12342 14.4811 9.32115 14.6789C10.7948 16.1525 13.1934 16.1552 14.6796 14.678C15.2739 14.0833 15.6209 13.3244 15.7346 12.5364C15.805 12.049 16.2571 11.7109 16.7446 11.7812Z'
        fill='#818181'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.96651 14.4869C6.96651 13.9944 7.36575 13.5952 7.85825 13.5952H10.5037C10.9962 13.5952 11.3954 13.9944 11.3954 14.4869C11.3954 14.9794 10.9962 15.3787 10.5037 15.3787H8.74999V17.1324C8.74999 17.6249 8.35075 18.0242 7.85825 18.0242C7.36575 18.0242 6.96651 17.6249 6.96651 17.1324V14.4869Z'
        fill='#818181'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.29282 12.1987C6.80538 12.1284 6.46726 11.6762 6.53761 11.1888C6.70125 10.055 7.20608 8.93144 8.09754 8.03998L8.09925 8.03828C10.2792 5.87013 13.8051 5.86768 15.9774 8.03998C16.275 8.33766 16.5385 8.67247 16.7555 9.03413C17.0089 9.45644 16.8719 10.0042 16.4496 10.2576C16.0273 10.511 15.4796 10.374 15.2262 9.95172C15.0865 9.71889 14.914 9.49883 14.7163 9.3011C13.2426 7.82745 10.844 7.82472 9.35779 9.30196C8.76354 9.89661 8.41654 10.6555 8.3028 11.4435C8.23245 11.931 7.78027 12.2691 7.29282 12.1987Z'
        fill='#818181'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M16.1416 5.9758C16.6341 5.9758 17.0333 6.37505 17.0333 6.86754V9.51303C17.0333 10.0055 16.6341 10.4048 16.1416 10.4048H13.4961C13.0036 10.4048 12.6044 10.0055 12.6044 9.51303C12.6044 9.02053 13.0036 8.62129 13.4961 8.62129H15.2499V6.86754C15.2499 6.37505 15.6491 5.9758 16.1416 5.9758Z'
        fill='#818181'
      />
    </svg>
  );
};

export default Refresh;
