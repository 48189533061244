import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Disconnect: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.34498 14.256L5.80845 12.7925C6.15992 12.441 6.15992 11.8712 5.80845 11.5197C5.45697 11.1682 4.88712 11.1682 4.53565 11.5197L1.46365 14.5917C1.11218 14.9432 1.11218 15.513 1.46365 15.8645L4.53565 18.9365C4.88712 19.288 5.45697 19.288 5.80845 18.9365C6.15992 18.585 6.15992 18.0152 5.80845 17.6637L4.2007 16.056L17.1 16.056C18.9259 16.056 20.3731 15.5343 21.3677 14.5723C22.3625 13.6102 22.8 12.3083 22.8 10.956C22.8 9.60363 22.3625 8.30172 21.3677 7.33963C20.3731 6.37766 18.9259 5.85596 17.1001 5.85596C16.603 5.85596 16.2001 6.2589 16.2001 6.75596C16.2001 7.25301 16.603 7.65596 17.1001 7.65596C18.5862 7.65596 19.539 8.07505 20.1164 8.63348C20.6936 9.19179 21 9.98988 21 10.956C21 11.922 20.6936 12.7201 20.1164 13.2784C19.539 13.8369 18.5862 14.256 17.1 14.256L4.34498 14.256Z'
        fill='#222222'
      />
    </svg>
  );
};

export default Disconnect;
