import { store } from "../store";
import * as userActions from "../reducers/user/actions";
import * as notificationActions from "../reducers/notification/actions";

const Requester = async (endpoint, payload = {}) => {
  const { user } = store.getState();

  //const server = appConfig[appConfig.env].api
  const server = "https://api.ticketandgo.com.br";
  //const server = "http://localhost:8000";
  //const server = 'https://painel.credenciamento.digital'

  if (user.isLogged) {
    payload = {
      ...payload,
      body: {
        ...Object.assign(payload.body || {}),
        uuid: user.uuid,
        uuid_evento: user.evento.uuid,
      },
    };
  }

  const headers = {
    ...payload,
    method: payload.method ?? "GET",
    headers: payload.headers ?? {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: payload.body ? JSON.stringify({ ...payload.body }) : "",
  };

  if (user?.token) {
    headers.headers["Authorization"] = `Bearer ${user.token}`;
  }

  if (headers.method === "GET") {
    delete headers["body"];
  }

  try {
    let ret = await fetch(`${server}${endpoint}`, headers);
    //let ret = await fetch('/assets/data/fakeServer.json')

    if (endpoint === "/v1/attendees/vcard") {
      return await ret.blob();
    }

    let result = await ret.json();

    if (ret.status === 401) {
      store.dispatch(userActions.logoff());
      store.dispatch(
        notificationActions.error(
          "Você foi desconectado. Realize novamente seu login"
        )
      );
      result["disconnected"] = true;
    }
    return result;
  } catch (e) {
    return { status: false };
  }
};

export const FRequester = async (endpoint, payload = {}) => {
  const { user } = store.getState();

  //const server = appConfig[appConfig.env].api
  const server = "/assets/data";

  const headers = {
    ...payload,
    method: "GET",
    headers: payload.headers ?? {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: payload.body ? JSON.stringify({ ...payload.body, event_id: 43 }) : "",
  };

  if (headers.method === "GET") {
    delete headers["body"];
  }

  try {
    let ret = await fetch(`${server}${endpoint}`, headers);
    //let ret = await fetch('/assets/data/fakeServer.json')

    if (endpoint === "/v1/attendees/vcard") {
      return await ret.blob();
    }

    let result = await ret.json();

    return result;
  } catch (e) {
    return { status: false };
  }
};

export default Requester;
