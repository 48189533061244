import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  createAnimation,
} from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import * as notificationActions from './reducers/notification/actions';

import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'react-toastify/dist/ReactToastify.min.css';

/* Theme variables */
import './theme/variables.css';
import HomePage from './pages/home/HomePage';

import OldLogin from './pages/Login';
import HomeOrLogin from './components/HomeOrLogin';
import Dash from './pages/dashboard';
import Dashboard2 from './pages/checkin';
import Checkin from './pages/checkin';
import UpdateAttendee from './pages/updateAttendee';
import AttendeeDetails from './pages/attendee/AttendeeDetails';
import SearchAttendee from './pages/searchAttendee';
import Login from './pages/auth/login';
import Dashboardv2 from './pages/dashboardv2';

const App = () => {
  const dispatch = useDispatch();

  const close = () => dispatch(notificationActions.clear());

  const notification = useSelector((state: any) => state.notification);
  const isLogged = useSelector((state: any) => state.user.isLogged);

  useEffect(() => {
    if (notification.type !== 'none') {
      switch (notification.type) {
        case 'success':
          toast.success(notification.message, {
            onClose: () => close(),
          });
          break;
        case 'info':
          toast.info(notification.message, {
            onClose: () => close(),
          });
          break;
        case 'warning':
          toast.warning(notification.message, {
            onClose: () => close(),
          });
          break;
        case 'error':
          toast.error(notification.message, {
            onClose: () => close(),
          });
          break;
        default:
          toast(notification.message, {
            onClose: () => close(),
          });
      }
    }
  }, [notification.message]);

  return (
    <IonApp className={`${false ? 'dark-theme' : ''}`}>
      {/*<ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />*/}
      <IonReactHashRouter>
        <IonSplitPane contentId='main'>
          {/*<Menu />*/}
          <IonRouterOutlet id='main' animated={false}>
            <Route path='/' component={HomeOrLogin} exact />
            <Route path='/auth/login' component={Login} exact />
            <Route path='/login' component={Login} exact />
            <Route path='/home' render={() => <HomePage />} />
            <Route path='/dashboard' render={() => <Dashboardv2 />} />
            <Route path='/dashboardv2' render={() => <Dashboardv2 />} />
            <Route path='/checkin' render={() => <Checkin />} />
            <Route path='/updateAttendee' render={() => <UpdateAttendee />} />
            <Route path='/searchAttendee' render={() => <SearchAttendee />} />
            <Route path='/attendee' render={() => <AttendeeDetails />} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactHashRouter>
    </IonApp>
  );
};

export default App;
