import React from 'react';

interface CheckinStatusProps {
  nome?: string;
  documento?: string;
  nome_ingresso?: string;
  status?: 'success' | 'error';
  cpf: string;
  codigo: string;
  errorMessage?: string;
}

const CheckinStatus: React.FC<CheckinStatusProps> = ({
  nome,
  nome_ingresso,
  cpf,
  codigo,
  status,
  errorMessage,
}) => {
  return (
    <div
      style={{
        padding: '14px',
        border: '1px solid #EEE',
        borderRadius: '8px',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          marginBottom: '4px',
          alignItems: 'center',
          display: 'flex',
          gap: '12px',
        }}
      >
        <img
          src={
            status === 'success'
              ? '/assets/icon/success.svg'
              : '/assets/icon/error.svg'
          }
          style={{
            width: '24px',
            height: '24px',
          }}
          alt='errororsuccess'
        />
        <h1
          style={{
            fontWeight: 600,
            margin: 0,
            color: status === 'success' ? '#3CCD6E' : '#FF5858',
            fontSize: '16px',
          }}
        >
          {errorMessage}
        </h1>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2px',
        }}
      >
        <p
          style={{
            fontWeight: 400,
            margin: 0,
            color: '#222',
            fontSize: '16px',
          }}
        >
          {nome}
        </p>
        <p
          style={{
            fontWeight: 400,
            margin: 0,
            color: '#222',
            fontSize: '12px',
          }}
        >
          {nome_ingresso}
        </p>
        <p
          style={{
            fontWeight: 400,
            margin: 0,
            color: '#888',
            fontSize: '12px',
          }}
        >
          Documento: {cpf}
        </p>
        <p
          style={{
            fontWeight: 400,
            margin: 0,
            color: '#888',
            fontSize: '12px',
          }}
        >
          Nº do ingresso: {codigo}
        </p>
      </div>
    </div>
  );
};

export default CheckinStatus;
