import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';

import { Box, Button, Typography } from '../../components/ui';
import styles from './checkin.module.css';
import { truncateString } from '../../util';
import QrCodeScanner from './scanner';
import Message from './message';
import PageLayout from '../../components/layout/Page';
import * as attendeesActions from './../../reducers/attendees/actions';

import { search as searchIcon } from 'ionicons/icons';
import Container from '../../components/ui/container';
const Checkin = () => {
  const dispatch = useDispatch();

  // redux
  const { evento_nome } = useSelector((state: any) => ({
    evento_nome: state.user?.evento?.nome || '',
  }));

  //Geração de qrcode
  const { errorMessage, errors } = useSelector((state: any) => ({
    errors: state.notification.errors,
    errorMessage: state.notification.message,
    state: state,
  }));

  const sendScan = (codigo: any) => dispatch(attendeesActions.scan(codigo));
  const [code, setCode] = useState('');
  const [localErrorMessage, setLocalErrorMessage] = useState('');
  const [localErrorsData, setLocalErrorsData] = useState<any>({});

  useEffect(() => {
    if (errorMessage && errors?.nome !== localErrorsData?.nome) {
      setLocalErrorMessage(errorMessage);
      setLocalErrorsData(errors);
    }
  }, [errorMessage, errors]);

  useEffect(() => {
    const resetErrorOnRouteChange = () => {
      setLocalErrorMessage('');
      setLocalErrorsData({});
    };

    window.addEventListener('hashchange', resetErrorOnRouteChange);

    return () => {
      window.removeEventListener('hashchange', resetErrorOnRouteChange);
    };
  }, []);

  const clearList = () => {
    setTimeout(() => {
      setCode('');
    }, 8500);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (code) {
        sendScan(code);
      }
    };
    fetchData();
    return () => clearList();
  }, [code]);

  useEffect(() => {}, []);

  function handleResult(result: any, error: any) {
    if (result) {
      const { text } = result || {};
      setCode(text);
    }
  }

  //função para redirecionar
  const history = useHistory();

  const handleRedirect = (url: string) => {
    history.push(url);
  };

  const setAttendee = (attendee: any) =>
    dispatch(attendeesActions.attendee(attendee));

  useEffect(() => {
    if (
      errorMessage ===
      'CPF inválido! Por favor, corrija os números e tente novamente.'
    ) {
      setAttendee({ redirect: '/checkin', ...errors });
      setTimeout(() => {
        handleRedirect('/updateAttendee');
      }, 400);
    }
  }, [errorMessage]);

  const isLogged = useSelector((state: any) => state.user.isLogged);

  if (!isLogged) {
    return <Redirect to={`/auth/login`} />;
  }

  return (
    <PageLayout
      pageId='checkin-page'
      navButtonProps={{
        onNavButtonClick: () => handleRedirect('/searchAttendee'),
        color: 'primary',
        icon: searchIcon,
      }}
    >
      <Container style={{ paddingBottom: '10px' }}>
        {/* titulo evento */}
        <Box className={styles.eventBoxTitle}>
          <Typography tag='h1' className={styles.eventTitle}>
            {truncateString(evento_nome, 30)}
          </Typography>
        </Box>
        {/* scanner de qrcode */}
        <QrCodeScanner handleResult={handleResult} />

        {/* Texto de ajuda e status pós ler qrcode */}
        <Message
          errorMessage={localErrorMessage}
          errorsData={localErrorsData}
        />
      </Container>
    </PageLayout>
  );
};

export default Checkin;
