import React from 'react';
import { IonIcon } from '@ionic/react';
import { menu } from 'ionicons/icons';
import { Box } from '../../../ui';
import styles from './menu.module.css';
import MenuIcon from '../../../icons/MenuIcon';

interface MenuIconButtonProps {
  showMenu: boolean;
  setShowMenu: (value: boolean) => void;
}

const MenuIconButton: React.FC<MenuIconButtonProps> = ({
  showMenu,
  setShowMenu,
}) => {
  return (
    <Box className={styles.menuBox} onClick={() => setShowMenu(!showMenu)}>
      <MenuIcon className={styles.menuIcon} />
    </Box>
  );
};

export default MenuIconButton;
