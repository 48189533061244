// Layout.tsx
import React, { useState } from 'react';
import MenuIconButton from './MenuIconButton';
import MenuBody from './MenuBody';
import * as userActions from '../../../../reducers/user/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const Menu: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const logoff = () => {
    setShowMenu(!showMenu);
    setTimeout(() => {
      dispatch(userActions.logoff());
      history.push('/auth/login');
    }, 300);
  };
  return (
    <>
      {/* botão de abrir menu */}
      <MenuIconButton showMenu={showMenu} setShowMenu={setShowMenu} />
      {/* body do menu */}
      <MenuBody logoff={logoff} showMenu={showMenu} setShowMenu={setShowMenu} />
    </>
  );
};

export default Menu;
