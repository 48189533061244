import React from 'react';

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const ArrowForward: React.FC<IconProps> = ({
  fill = 'currentColor',
  width = '24px',
  height = '24px',
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.73248 5.98895C8.381 6.34042 8.38101 6.91027 8.73248 7.26174L13.5687 12.0979C13.7884 12.3176 13.7884 12.6824 13.5687 12.902L8.73248 17.7382C8.38101 18.0897 8.381 18.6595 8.73247 19.011C9.08394 19.3625 9.65379 19.3625 10.0053 19.011L14.8415 14.1748C15.7641 13.2522 15.7641 11.7478 14.8415 10.8252L10.0053 5.98894C9.65379 5.63747 9.08395 5.63747 8.73248 5.98895Z'
        fill='#222222'
        fill-opacity='0.56'
      />
    </svg>
  );
};

export default ArrowForward;
